import React from "react";
import { Row, Col, Card, Image } from "antd";

const awards = [
  {
    icon: "https://www.amanethiopiatours.com/wp-content/uploads/elementor/thumbs/TripAdvisor-q6geef131n8isggcgc505sg0zrq21f44r4cdsajpfo.png",
    name: "#1 Tours & Activities in Addis Ababa",
    description: "We are a leading tour and travel operator in Ethiopia.",
  },
  {
    icon: "https://www.amanethiopiatours.com/wp-content/uploads/elementor/thumbs/TC_LL_2023-q8e12jg80tepjl7lxnzrgfbmdkc5783i7y2a52haeg.png",
    name: "2023 TripAdvisor Travelers’ Choice Awards",
    description: "We are a winner of the 2023 TripAdvisor Travelers’ Choice Awards.",
  },
 
  // {
  //   icon: "https://www.amanethiopiatours.com/wp-content/uploads/elementor/thumbs/World-Travel-Awards-2020-p1qk0d0bur0p6m3pl3hm2rg0n2xgtgwjbvqrzwxoo0.png",
  //   name: "Ethiopia’s Leading Tour Operator 2023, 2022, 2021 & 2020",
  //   description: "We are the winner of World Travel Awards for four consecutive years.",
  // },
  // {
  //   icon: "https://www.amanethiopiatours.com/wp-content/uploads/elementor/thumbs/World-Travel-Awards-2020-p1qk0d0bur0p6m3pl3hm2rg0n2xgtgwjbvqrzwxoo0.png",
  //   name: "Ethiopia’s Leading Tour Operator 2023, 2022, 2021 & 2020",
  //   description: "We are the winner of World Travel Awards for four consecutive years.",
  // }
];

const WhyTravelPage = () => {
  return (
    <div style={{ padding: "20px", background: "#CCCCCC" }}>
      <h1 style={{ textAlign: "center", marginBottom: "30px" }}>Why Travel with Enat Ethiopia Tours?</h1>
      <Row gutter={16}>
        {awards.map((award, index) => (
          <Col xs={24} sm={12} key={index}>
            <Card style={{ marginBottom: "20px" }}>
              <Row gutter={16} align="middle">
                <Col span={6}>
                <Image 
  src={award.icon} 
  width={80} 
  className="rounded-lg shadow-md p-2 bg-gray-200"
/>

                </Col>
                <Col span={18}>
                <h2 className="text-blue-600 bg-white text-base">{award.name}</h2>

                  <p>{award.description}</p>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default WhyTravelPage;
