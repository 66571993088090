import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchBlogPosts } from '../utils/API/blogPostService';
import { Pagination } from 'antd';

const BlogPosts = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const posts = await fetchBlogPosts();
        setBlogPosts(posts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchPosts();
  }, []);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  return (
    <div className="bg-gray-200 py-10">
      <div className="container mx-auto px-4">
      <div className="text-center mb-6">
  <h1 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-2 bg-gradient-to-r from-gray-200 to-gray-400 inline-block px-4 py-2 rounded">
    Latest Blog Posts
  </h1>
  <p className="text-lg text-gray-600 mt-2">
    Check out our newest blog posts below:
  </p>
</div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {blogPosts.slice((currentPage - 1) * pageSize, currentPage * pageSize).map(blogPost => (
            <Link key={blogPost._id} to={`/blog/${blogPost._id}`} className="group block overflow-hidden hover:opacity-80 transition duration-300">
              <img src="https://5.imimg.com/data5/QN/XH/DH/SELLER-15963127/all-types-of-tour-services-1000x1000.jpg" alt={blogPost.title} className="w-full h-32 object-cover" />
              <div className="p-6 ">
                <h2 className="text-lg font-semibold text-gray-800 mb-2">{blogPost.title}</h2>
                <p className="text-sm text-gray-600">{blogPost.content.substring(0, 100)}...</p>
              </div>
            </Link>
          ))}
        </div>
        {blogPosts.length > pageSize && (
          <div className="flex justify-center mt-8">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={blogPosts.length}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPosts;
