import React from "react";
import { Link } from "react-router-dom";
import { FaTripadvisor } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import AdminDashboard from "../admin/Dashboard/AdminDashboard";
import { FacebookOutlined, TwitterOutlined, InstagramOutlined, MailOutlined, PhoneOutlined, EnvironmentOutlined } from "@ant-design/icons";

const FooterComponent = () => {
  const year = new Date().getFullYear();
  const { user } = useContext(AuthContext);

  return (
    <div>
    <footer style={{ backgroundColor: "#f8f9fa", color: "#000", padding: "50px 0" }}>
      <div className="container" style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>

        <div style={{ flex: "1", minWidth: "200px", padding: "10px" }}>
  <h5 style={{ marginBottom: "20px", fontFamily: "Arial, sans-serif" }}>Get in Touch</h5>
  <div className="contact-info" style={{ marginBottom: "20px" }}>
    <a href="tel:+251923654512" className="flex items-center text-black text-lg" style={{ textDecoration: "none" }}>
      <PhoneOutlined style={{ fontSize: "24px", marginRight: "10px" }} />
      <span>+251923654512</span>
    </a>
  </div>
  <div className="contact-info" style={{ marginBottom: "20px" }}>
    <a href="tel:+251923654512" className="flex items-center text-black text-lg" style={{ textDecoration: "none" }}>
      <PhoneOutlined style={{ fontSize: "24px", marginRight: "10px" }} />
      <span>+251942721821</span>
    </a>
  </div>
  <div className="contact-info" style={{ marginBottom: "20px" }}>
    <a href="mailto:ethiopiantraveltours@gmail.com" className="flex items-center text-black text-lg" style={{ textDecoration: "none" }}>
      <MailOutlined style={{ fontSize: "24px", marginRight: "10px" }} />
      <span>ethiopiantraveltours@gmail.com</span>
    </a>
  </div>
  <div className="contact-info" style={{ marginBottom: "20px" }}>
    <a href="mailto:ethiopiantraveltours@gmail.com" className="flex items-center text-black text-lg" style={{ textDecoration: "none" }}>
      <MailOutlined style={{ fontSize: "24px", marginRight: "10px" }} />
      <span>achenefasnake@yahoo.com</span>
    </a>
  </div>
  <div className="contact-info" style={{ marginTop: "20px" }}>
    <span className="flex items-center text-black text-lg" style={{ textDecoration: "none" }}>
      <EnvironmentOutlined style={{ fontSize: "24px", marginRight: "10px" }} />
      <span>Shalla guest house 1st-floor-room-110, Addis Ababa, Ethiopia</span>
    </span>
  </div>
</div>


         {/* About Column */}
<div style={{ flex: "1", minWidth: "200px", padding: "10px" ,fontSize: "1.1rem"}}>
  <h5 className="mb-4">ABOUT</h5>
  <ul style={{ listStyleType: "none", padding: 0 }}>
    <li style={{ marginBottom: "12px" }}><Link to="/about" style={{ color: "#000", textDecoration: "none" }}>About Us</Link></li>
    <li style={{ marginBottom: "12px" }}><Link to="/contact" style={{ color: "#000", textDecoration: "none" }}>Contact Us</Link></li>
    <li style={{ marginBottom: "12px" }}><Link to="/terms" style={{ color: "#000", textDecoration: "none" }}>Terms and Conditions</Link></li>
    <li style={{ marginBottom: "12px" }}><Link to="/privacy" style={{ color: "#000", textDecoration: "none" }}>Privacy Policy</Link></li>
    <li style={{ marginBottom: "12px" }}><Link to="/traveltips" style={{ color: "#000", textDecoration: "none" }}>Travel and Tips</Link></li>
  </ul>
</div>


          {/* TripAdvisor & Social Icons Column */}
          <div style={{ flex: "1", minWidth: "200px", padding: "10px", textAlign: "center" }}>
          <div class="tripadvisor-card  text-center">
            <div className="mr-24">
            <h1 class="text-black text-lg font-semibold mr-16  mb-4">Write A Review</h1>
  <a href="https://www.tripadvisor.com/Attraction_Review-g293791-d16644741-Reviews-Enat_Ethiopia_Tours_Travel-Addis_Ababa.html" target="_blank" rel="noopener noreferrer" class="flex flex-col items-center">
    <img src="https://www.amanethiopiatours.com/wp-content/uploads/elementor/thumbs/TripAdvisor-logo-p1qkhswjox271jgb3sqdld6cag9mv8shkqpxtyfp4k.png" alt="TripAdvisor Logo" class="mb-2 w-48 h-auto"/>
    
    
  </a>
  <p class="text-gray-600 text-sm">Recommended on TripAdvisor</p>
            </div>
      
  <div className="social-icons flex flex-col items-center mb-4 mt-8 " style={{ maxWidth: "300px" }}>
  <h5 className="text-gray-600  mb-4 mr-32">Follow Us</h5>
  <div className="flex mr-48" style={{ width: "32px" }}>
    <div style={{ marginRight: "20px", borderRadius: "50%", backgroundColor: "#3b5998", padding: "10px" }}>
      <a href="https://www.facebook.com/EnatEthiopiaTours/" target="_blank" rel="noopener noreferrer" style={{ color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", width: "36px", height: "36px" }}>
        <FacebookOutlined style={{ fontSize: "24px" }} />
      </a>
    </div>
    <div style={{ marginRight: "20px", borderRadius: "50%", backgroundColor: "#1da1f2", padding: "10px" }}>
      <a href="https://twitter.com/achenefasnake" target="_blank" rel="noopener noreferrer" style={{ color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", width: "36px", height: "36px" }}>
        <TwitterOutlined style={{ fontSize: "24px" }} />
      </a>
    </div>
    <div style={{ borderRadius: "50%", backgroundColor: "#c32aa3", padding: "10px" }}>
      <a href="https://www.instagram.com/enat_ethiopia_tours/" target="_blank" rel="noopener noreferrer" style={{ color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", width: "36px", height: "36px" }}>
        <InstagramOutlined style={{ fontSize: "24px" }} />
      </a>
    </div>
</div>

</div>

</div>

          </div>
        </div>

       

        {user && user.role === "admin" && <AdminDashboard name={user.data.username} />}
      </div>
      
    </footer>

    <div style={{ textAlign: "center", marginTop: "50px", backgroundColor: "black", padding: "20px" ,marginBottom:"20px"}}>
  <p style={{ fontSize: "18px", color: "#fff", fontFamily: "Arial, sans-serif", margin: 0 }}>&copy; {year} Enat Ethiopia Tours. All rights reserved.</p>
</div>
    </div>
  );
};

export default FooterComponent;
