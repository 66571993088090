import React, { useState, useContext } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  FormControl,
  Card,
  CardContent,
  Grid,
  Switch,
  FormControlLabel,InputLabel,Select,MenuItem
} from "@material-ui/core";
import { AuthContext } from "../../context/AuthContext";
import { createTour } from "../Api/Tour";
import { FaTrash } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { CheckCircle } from '@material-ui/icons';



const tourTypes = [
  "Addis Ababa City Tours",
  "Day Trips & Excursions",
  "Group Tour",
  "Northern Ethiopia",
  "Southern Ethiopia",
  "Eastern Ethiopia",
  "Fixed-Date Departure Tours",
  "Multi-Day Tours",
  "Activity",
 "Trekking and Hiking",
  "Adventure Tours",
  "Coffee Tours",
    "Birding Tours",
    "Safari"
];


const CreateTourForm = () => {
  const { user } = useContext(AuthContext);
  const [activeStep, setActiveStep] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    city: "",
    address: "",
    distance: "",
    photo: "",
    desc: "",
    price: "",
    maxGroupSize: "",
    featured: false,
    included: [],
    excluded: [],
    highlights: [],
    itinerary: [],
    photos: [],
    videos:[],
    metaTitle: "", // Added metaTitle field to form data
    metaDescription: "", // Added metaDescription field to form data
    keywords: [],// Added keywords field to form data,
    tourType:""
    
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    try {
      const token = user.token;
      await createTour(formData, token);

     // Show success message
    setShowSuccessMessage(true);
      // Handle success
      console.log("Tour created successfully!");
    } catch (error) {
      console.error("Error creating tour:", error);
      // Handle error
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };


  

  
  

  const handleAddItineraryItem = () => {
    // Check if formData.itinerary is undefined
    if (formData.itinerary === undefined) {
      // If it's undefined, initialize it as an empty array
      setFormData({
        ...formData,
        itinerary: [{ day: 0, description: "" }]
      });
    } else {
      // If it's defined, append a new empty itinerary object to it
      setFormData({
        ...formData,
        itinerary: [...formData.itinerary, { day: 0, description: "" }]
      });
    }
  };
  

  const handleItineraryChange = (index, field, value) => {
    const updatedItinerary = [...formData.itinerary];
    updatedItinerary[index][field] = value;
    setFormData({
      ...formData,
      itinerary: updatedItinerary
    });
  };

  const handleRemoveItineraryItem = (index) => {
    const updatedItinerary = [...formData.itinerary];
    updatedItinerary.splice(index, 1);
    setFormData({ ...formData, itinerary: updatedItinerary });
  };


  const handleFileUpload = async (files) => {
    try {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('upload_preset', 'hq840p7x'); // Replace 'YOUR_UPLOAD_PRESET' with your upload preset name
  
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dx9lkfhua/upload`,
        {
          method: 'POST',
          body: formData,
        }
      );
  
      const data = await response.json();
      console.log("Image data:", data);
  
      // Update the form data with the uploaded photo URL
      setFormData((prevFormData) => ({
        ...prevFormData,
        photo: data.secure_url,
      }));
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  
  // Modify the handleFileUpload function to handle multiple file uploads
  const handleFileUploads = async (files) => {
    try {
      const uploadedPhotos = [];
      for (const file of files) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'hq840p7x');
  
        const response = await fetch(
          `https://api.cloudinary.com/v1_1/dx9lkfhua/upload`,
          {
            method: 'POST',
            body: formData,
          }
        );
  
        const data = await response.json();
        uploadedPhotos.push(data.secure_url);
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        photos: [...(prevFormData.photos || []), ...uploadedPhotos], // Ensure prevFormData.photos is initialized
      }));
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };
  
  const handleAddKeyword = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      keywords: [...(prevFormData.keywords || []), ""]
    }));
  };
  
const handleDeletePhoto = (index) => {
  const updatedPhotos = [...formData.photos];
  updatedPhotos.splice(index, 1); // Remove the photo at the specified index
  setFormData({ ...formData, photos: updatedPhotos });
};


  const steps = [
    "Basic Information",
    "Details",
    "Pricing & Group Size",
    "Features & Highlights",
    "Itinerary"
  ];

  const handleAddInclude = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      included: [...(prevFormData.included || []), ""]
    }));
  };
  const handleAddExclude = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      excluded: [...(prevFormData.excluded || []), ""]
    }));
  };
  
  const handleAddHighlight = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      highlights: [...(prevFormData.highlights || []), ""]
    }));
  };
  const handleAddVideo = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      videos: [...(prevFormData.videos || []), ""]
    }));
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };
  
  
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Card variant="outlined" className="p-6 mb-6">
            <CardContent className="space-y-6">
              <FormControl fullWidth>
                <TextField
                  label="Title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                  size="small"
                  variant="outlined"
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label="City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                  size="small"
                  variant="outlined"
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                  size="small"
                  variant="outlined"
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label="Distance"
                  name="distance"
                  value={formData.distance}
                  onChange={handleChange}
                  required
                  size="small"
                  type="number"
                  variant="outlined"
                />
              </FormControl>
            </CardContent>
          </Card>
        );
        case 1:
          return (
            <Card variant="outlined" className="p-6 mb-6">
              <CardContent className="space-y-6">
                <FormControl fullWidth>
                  <TextField
                    label="Description"
                    name="desc"
                    value={formData.desc}
                    onChange={handleChange}
                    required
                    size="small"
                    multiline
                    rows={4}
                    variant="outlined"
                  />
                </FormControl>
  
                <FormControl fullWidth>
                  <input
                    type="file"
                    onChange={(e) => handleFileUpload(e.target.files)}
                    required
                  />
                </FormControl>
  
                <FormControl fullWidth variant="outlined">
                  <Select
                    labelId="tour-type-label"
                    id="tour-type"
                    name="tourType"
                    value={formData.tourType}
                    onChange={handleChange}
                    required
                    size="small"
                    displayEmpty
                    renderValue={(selected) => (selected ? selected : "Tour Type")}
                  >
                    {tourTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
  
                <FormControl fullWidth>
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => handleFileUploads(e.target.files)}
                    required
                  />
                </FormControl>
  
                {formData.photos && (
                  <Grid container spacing={2}>
                    {formData.photos.map((photo, index) => (
                      <Grid item xs={3} key={index}>
                        <div style={{ position: "relative" }}>
                          <img
                            src={photo}
                            alt={`Photo ${index + 1}`}
                            style={{ width: "100%", height: "auto" }}
                          />
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            style={{ position: "absolute", top: 5, right: 5 }}
                            onClick={() => handleDeletePhoto(index)}
                          >
                            <FaTrash />
                          </Button>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </CardContent>
            </Card>
          )
      case 2:
        return (
          <Card variant="outlined" style={{ marginBottom: "20px" }}>
          <CardContent>
            <FormControl fullWidth style={{ marginBottom: "20px" }}>
              <TextField
                label="Price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
                size="small"
                type="number"
              />
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: "20px" }}>
              <TextField
                label="Max Group Size"
                name="maxGroupSize"
                value={formData.maxGroupSize}
                onChange={handleChange}
                required
                size="small"
                type="number"
              />
            </FormControl>
            {formData.itinerary &&
              formData.itinerary.map((item, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                  <FormControl
                    fullWidth
                    style={{ marginBottom: "10px", marginRight: "10px" }}
                  >
                    <TextField
                      label={`Day ${index + 1}`}
                      value={item.day}
                      onChange={(e) =>
                        handleItineraryChange(index, "day", e.target.value)
                      }
                      required
                      size="small"
                      type="number"
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    style={{ marginBottom: "10px", marginRight: "10px" }}
                  >
                    <TextField
                      label="Description"
                      value={item.description}
                      onChange={(e) =>
                        handleItineraryChange(
                          index,
                          "description",
                          e.target.value
                        )
                      }
                      required
                      size="small"
                      multiline
                      rows={4}
                    />
                  </FormControl>
                  {index > 0 && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemoveItineraryItem(index)}
                      style={{ marginTop: "10px" }}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              ))}
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddItineraryItem}
            >
              Add Day
            </Button>
          </CardContent>
        </Card>
        );
      case 3:
        return (
          <Card variant="outlined" style={{ marginBottom: "20px" }}>
          <CardContent>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.featured}
                  onChange={handleSwitchChange}
                  name="featured"
                  color="primary"
                />
              }
              label="Featured"
              style={{ marginBottom: "20px" }}
            />
            <div style={{ marginBottom: "20px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddHighlight}
                style={{ marginRight: "10px" }}
              >
                Add Highlight
              </Button>
              {formData.highlights &&
                formData.highlights.map((highlight, index) => (
                  <FormControl fullWidth key={index} style={{ marginBottom: "10px" }}>
                    <TextField
                      label="Highlight"
                      value={formData.highlights[index]}
                      onChange={(e) => {
                        const highlights = [...formData.highlights];
                        highlights[index] = e.target.value;
                        setFormData({ ...formData, highlights });
                      }}
                      required
                      size="small"
                      multiline
                      rows={4}
                    />
                  </FormControl>
                ))}
            </div>
            <div style={{ marginBottom: "20px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddVideo}
                style={{ marginRight: "10px" }}
              >
                Add Video
              </Button>
              {formData.videos &&
                formData.videos.map((video, index) => (
                  <FormControl fullWidth key={index} style={{ marginBottom: "10px" }}>
                    <TextField
                      label="Video Link"
                      value={formData.videos[index]}
                      onChange={(e) => {
                        const videos = [...formData.videos];
                        videos[index] = e.target.value;
                        setFormData({ ...formData, videos });
                      }}
                      required
                      size="small"
                    />
                  </FormControl>
                ))}
            </div>
          </CardContent>
        </Card>
        );
      case 4:
        return (
          <Card variant="outlined" style={{ width: "100%", marginBottom: "20px", borderRadius: "15px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", transition: "0.3s", backgroundColor: "#f5f5f5" }}>
  <CardContent>
    <div style={{ marginBottom: "20px" }}>
      <Button variant="contained" color="primary" onClick={handleAddInclude} style={{ marginRight: "10px" }}>
        Add Include
      </Button>
      {formData.included && formData.included.map((include, index) => (
        <FormControl fullWidth key={index} style={{ marginBottom: "10px" }}>
          <TextField
            label="Included"
            value={formData.included[index]}
            onChange={(e) => {
              const included = [...formData.included];
              included[index] = e.target.value;
              setFormData({ ...formData, included });
            }}
            required
            size="small"
            multiline
            rows={4}
            variant="outlined"
          />
        </FormControl>
      ))}
    </div>
    <div style={{ marginBottom: "20px" }}>
      <Button variant="contained" color="primary" onClick={handleAddExclude} style={{ marginRight: "10px" }}>
        Add Exclude
      </Button>
      {formData.excluded && formData.excluded.map((exclude, index) => (
        <FormControl fullWidth key={index} style={{ marginBottom: "10px" }}>
          <TextField
            label="Excluded"
            value={formData.excluded[index]}
            onChange={(e) => {
              const excluded = [...formData.excluded];
              excluded[index] = e.target.value;
              setFormData({ ...formData, excluded });
            }}
            required
            size="small"
            multiline
            rows={4}
            variant="outlined"
          />
        </FormControl>
      ))}
    </div>
    <FormControl fullWidth style={{ marginBottom: "20px" }}>
      <TextField
        label="Meta Title"
        name="metaTitle"
        value={formData.metaTitle}
        onChange={handleChange}
        required
        size="small"
        multiline
        rows={2}
        variant="outlined"
      />
    </FormControl>
    <FormControl fullWidth style={{ marginBottom: "20px" }}>
      <TextField
        label="Meta Description"
        name="metaDescription"
        value={formData.metaDescription}
        onChange={handleChange}
        required
        size="small"
        multiline
        rows={4}
        variant="outlined"
      />
    </FormControl>
    <div style={{ marginBottom: "20px" }}>
      <Button variant="contained" color="primary" onClick={handleAddKeyword} style={{ marginRight: "10px" }}>
        Add Keyword
      </Button>
      {formData.keywords && formData.keywords.map((keyword, index) => (
        <FormControl fullWidth key={index} style={{ marginBottom: "10px" }}>
          <TextField
            label="Keyword"
            value={formData.keywords[index]}
            onChange={(e) => {
              const keywords = [...formData.keywords];
              keywords[index] = e.target.value;
              setFormData({ ...formData, keywords });
            }}
            required
            size="small"
            multiline
            rows={2}
            variant="outlined"
          />
        </FormControl>
      ))}
    </div>
  </CardContent>
</Card>

        );
      default:
        return <Typography>Unknown step</Typography>;
    }
  };
  return (
    <div>
      <Card variant="outlined" style={{ maxWidth: "600px", margin: "auto" }}>
        <CardContent>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography>All steps completed</Typography>
                <Button onClick={() => setActiveStep(0)}>Reset</Button>
              </div>
            ) : (
              <div>
                <form>
                  <Grid container spacing={2}>
                    {getStepContent(activeStep)}
                  </Grid>
                </form>
                <div style={{ marginTop: "20px" }}>
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Create Tour
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
          <Snackbar
  open={showSuccessMessage}
  autoHideDuration={6000}
  onClose={handleCloseSuccessMessage}
>
  <SnackbarContent
    style={{ backgroundColor: green[600] }}
    message={
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <CheckCircle style={{ marginRight: '8px' }} />
        Tour created successfully!
      </span>
    }
  />
</Snackbar>


        </CardContent>
      </Card>
    </div>
  );
};

export default CreateTourForm;
