import React, { useEffect, useState } from 'react';
import { fetchTours } from '../../../utils/API/ToursService';
import { FaMapMarkerAlt, FaDollarSign } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai'; // Using different icon for "View Details"
import { Pagination } from 'antd';

const NewOfferings = () => {
  const [tours, setTours] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allTours = await fetchTours();
        const randomTours = getRandomTours(allTours.data);
        setTours(randomTours);
      } catch (error) {
        console.error('Error fetching tours: ', error);
      }
    };

    fetchData();
  }, []);

  const getRandomTours = (allTours) => {
    if (allTours.length <= pageSize) {
      return allTours;
    } else {
      const shuffledTours = allTours.sort(() => 0.5 - Math.random());
      return shuffledTours.slice(0, pageSize);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderTours = () => {
    if (!Array.isArray(tours)) {
      console.error('Invalid tours data: ', tours);
      return null;
    }

    if (tours.length === 0) {
      return <p>No tours available.</p>;
    }

    return tours.map(tour => (
      <div key={tour._id} className="w-full sm:w-1/2 md:w-1/3 p-1 md:p-2"> {/* Decreased width and padding */}
        <div className=" overflow-hidden">
          <img src={tour.photo} alt={tour.title} className="w-full h-48 object-cover" />
          <div className="p-2 md:p-4"> {/* Decreased padding for smaller screens */}
          <h3 className="text-lg font-semibold mb-1 text-blue-900">{tour.title}</h3>
 {/* Decreased margin bottom */}
            <div className="flex items-center text-gray-600 mb-1">
              <FaMapMarkerAlt className="h-4 w-4 mr-1" />
              <span>{tour.address}</span>
            </div>
            <div className="flex items-center text-gold"> {/* Changed text color to golden */}
              <FaDollarSign className="h-4 w-4 mr-1" />
              <span>{tour.price}</span>
            </div>
          </div>
          <div className="p-2 md:p-4 flex justify-between items-center ">
            <a href={`/tours/${tour._id}`} className="text-blue-500 hover:text-blue-600 flex items-center"> {/* Used a different icon */}
              View Details <AiOutlineInfoCircle className="h-4 w-4 ml-1" />
            </a>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="container mx-auto py-12">
     <div className="text-center mb-8">
  <h2 className="text-3xl font-bold text-blue-900 pb-2 border-b-4 border-blue-900">
    New Offerings for 2024/25
  </h2>
  <p className="text-lg text-gray-700 italic mt-4 leading-loose">
  Discover our <span className="text-blue-900 font-semibold">latest tours</span> and embark on <span className="text-green-700 font-semibold">unforgettable adventures</span>.
</p>

</div>

      <div className="flex flex-wrap justify-center">
        {renderTours()}
      </div>
      {tours.length >= 6 && (
        <div className="flex justify-center mt-8">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={tours.length}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default NewOfferings;
