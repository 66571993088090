import React, { useState, useContext } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  FormControl,
  Card,
  CardContent,
  Grid,
  IconButton,
  Snackbar,
  SnackbarContent,
  Box
} from "@material-ui/core";
import { AuthContext } from "../../context/AuthContext";
import { FaTrash, FaPlus } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async';
import { green } from '@material-ui/core/colors';
import { CheckCircle, Error } from '@material-ui/icons';
import { createDestination } from "../../utils/API/destinationService";

const CreateTourForm = () => {
  const { user } = useContext(AuthContext);
  const [activeStep, setActiveStep] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    coverImage: "",
    images: [],
    videos: [],
    placesToVisit: [],
    location: "",
    climate: "",
    averageTemperature: 0,
    recommendedMonths: [],
    popularActivities: [],
    travelTips: [],
    relatedToursId: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    metaTitle: "",
    metaDescription: "",
    keywords: []
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    try {
      const token = user.token;
      await createDestination(formData, token);
      setShowSuccessMessage(true);
      console.log("Tour created successfully!");
    } catch (error) {
      setShowErrorMessage(true);
      console.error("Error creating tour:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = async (files) => {
    try {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('upload_preset', 'hq840p7x');
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dx9lkfhua/upload`,
        {
          method: 'POST',
          body: formData,
        }
      );
      const data = await response.json();
      console.log("Image data:", data);
      setFormData((prevFormData) => ({
        ...prevFormData,
        coverImage: data.secure_url,
      }));
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleFileUploads = async (files) => {
    try {
      const uploadedPhotos = [];
      for (const file of files) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'hq840p7x');
        const response = await fetch(
          `https://api.cloudinary.com/v1_1/dx9lkfhua/upload`,
          {
            method: 'POST',
            body: formData,
          }
        );
        const data = await response.json();
        uploadedPhotos.push(data.secure_url);
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        images: [...(prevFormData.images || []), ...uploadedPhotos],
      }));
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const handleAddKeyword = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      keywords: [...(prevFormData.keywords || []), ""]
    }));
  };

  const handleDeletePhoto = (index) => {
    const updatedPhotos = [...formData.images];
    updatedPhotos.splice(index, 1);
    setFormData({ ...formData, images: updatedPhotos });
  };

  const handleAddItem = (field) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [field]: [...(prevFormData[field] || []), ""]
    }));
  };

  const handleDeleteItem = (field, index) => {
    const updatedItems = [...formData[field]];
    updatedItems.splice(index, 1);
    setFormData({ ...formData, [field]: updatedItems });
  };

  const handleCloseMessage = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
  };

  const steps = [
    "Basic Information",
    "Media",
    "Details",
    "SEO"
  ];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box mb={3}>
            <Card variant="outlined">
              <CardContent>
                <FormControl fullWidth>
                  <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    size="small"
                    variant="outlined"
                    margin="normal"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label="Description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                    size="small"
                    multiline
                    rows={4}
                    variant="outlined"
                    margin="normal"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label="Average Temperature"
                    name="averageTemperature"
                    value={formData.averageTemperature}
                    onChange={handleChange}
                    required
                    size="small"
                    type="number"
                    variant="outlined"
                    margin="normal"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label="Location"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    required
                    size="small"
                    variant="outlined"
                    margin="normal"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label="Climate"
                    name="climate"
                    value={formData.climate}
                    onChange={handleChange}
                    required
                    size="small"
                    variant="outlined"
                    margin="normal"
                  />
                </FormControl>
              </CardContent>
            </Card>
          </Box>
        );
      case 1:
        return (
          <Box mb={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Upload Cover Photo</Typography>
                <FormControl fullWidth margin="normal">
                  <input
                    type="file"
                    onChange={(e) => handleFileUpload(e.target.files)}
                    required
                  />
                </FormControl>
                <Box mt={3}>
                  <Typography variant="h6">Upload Gallery Images</Typography>
                  <FormControl fullWidth margin="normal">
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={(e) => handleFileUploads(e.target.files)}
                      required
                    />
                  </FormControl>
                  {formData.images && (
                    <Grid container spacing={2}>
                      {formData.images.map((photo, index) => (
                        <Grid item xs={3} key={index}>
                          <div className="relative">
                            <img
                              src={photo}
                              alt={`Photo ${index + 1}`}
                              className="w-full h-auto"
                            />
                            <IconButton
                              onClick={() => handleDeletePhoto(index)}
                              style={{ position: 'absolute', top: 0, right: 0 }}
                            >
                              <FaTrash />
                            </IconButton>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Box>
        );
      case 2:
        return (
          <Box mb={3}>
            <Card variant="outlined">
              <CardContent>
                <Button onClick={() => handleAddItem('videos')} startIcon={<FaPlus />}>
                  Add Video
                </Button>
                {formData.videos.map((video, index) => (
                  <FormControl fullWidth key={index}>
                    <TextField
                      label="Video Link"
                      value={video}
                      onChange={(e) => {
                        const videos = [...formData.videos];
                        videos[index] = e.target.value;
                        setFormData({ ...formData, videos });
                      }}
                      required
                      size="small"
                      variant="outlined"
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={() => handleDeleteItem('videos', index)}>
                            <FaTrash />
                          </IconButton>
                        )
                      }}
                    />
                  </FormControl>
                ))}
                <Button onClick={() => handleAddItem('placesToVisit')} startIcon={<FaPlus />}>
                  Add Places to Visit
                </Button>
                {formData.placesToVisit.map((place, index) => (
                  <FormControl fullWidth key={index}>
                    <TextField
                      label="Place to Visit"
                      value={place}
                      onChange={(e) => {
                        const placesToVisit = [...formData.placesToVisit];
                        placesToVisit[index] = e.target.value;
                        setFormData({ ...formData, placesToVisit });
                      }}
                      required
                      size="small"
                      variant="outlined"
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={() => handleDeleteItem('placesToVisit', index)}>
                            <FaTrash />
                          </IconButton>
                        )
                      }}
                    />
                  </FormControl>
                ))}
              </CardContent>
            </Card>
          </Box>
        );
      case 3:
        return (
          <Box mb={3}>
            <Card variant="outlined">
              <CardContent>
                <FormControl fullWidth>
                  <TextField
                    label="Meta Title"
                    name="metaTitle"
                    value={formData.metaTitle}
                    onChange={handleChange}
                    required
                    size="small"
                    variant="outlined"
                    margin="normal"
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label="Meta Description"
                    name="metaDescription"
                    value={formData.metaDescription}
                    onChange={handleChange}
                    required
                    multiline
                    rows={4}
                    variant="outlined"
                    margin="normal"
                  />
                </FormControl>
                <Button onClick={handleAddKeyword} startIcon={<FaPlus />}>
                  Add Keyword
                </Button>
                {formData.keywords.map((keyword, index) => (
                  <FormControl fullWidth key={index}>
                    <TextField
                      label="Keyword"
                      value={keyword}
                      onChange={(e) => {
                        const keywords = [...formData.keywords];
                        keywords[index] = e.target.value;
                        setFormData({ ...formData, keywords });
                      }}
                      required
                      size="small"
                      variant="outlined"
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={() => handleDeleteItem('keywords', index)}>
                            <FaTrash />
                          </IconButton>
                        )
                      }}
                    />
                  </FormControl>
                ))}
              </CardContent>
            </Card>
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <div>
      <Helmet>
        <title>Create a Tour</title>
      </Helmet>
      <Typography variant="h4" gutterBottom>
        Create a Tour
      </Typography>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography variant="h6" gutterBottom>
              All steps completed
            </Typography>
          </div>
        ) : (
          <div>
            {getStepContent(activeStep)}
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={handleNext}>
                  Next
                </Button>
              )}
            </Box>
          </div>
        )}
      </div>
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={6000}
        onClose={handleCloseMessage}
      >
        <SnackbarContent
          style={{ backgroundColor: green[600] }}
          message={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle style={{ marginRight: 8 }} />
             Destination created successfully!
            </span>
          }
        />
      </Snackbar>
      <Snackbar
        open={showErrorMessage}
        autoHideDuration={6000}
        onClose={handleCloseMessage}
      >
        <SnackbarContent
          style={{ backgroundColor: 'red' }}
          message={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Error style={{ marginRight: 8 }} />
              Error creating destinations. Please try again.
            </span>
          }
        />
      </Snackbar>
    </div>
  );
};

export default CreateTourForm;
