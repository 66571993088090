import React from "react";
import "../styles/home.css";
import { Container, Row, Col, CardSubtitle } from "reactstrap";
import WhyTravelPage from "./WhyChooseUs";
import experienceImg from "../assets/images/experience.png";

import Subtitle from "./../shared/subtitle";
import SearchBar from "./../shared/SearchBar";
import ServiceList from "../services/ServiceList";
import FeaturedTourList from "../components/Featured-tours/FeaturedTourList";
import MasonryImagesGallery from "../components/Image-gallery/MasonryImagesGallery";
import Testimonials from "../components/Testimonial/Testimonials";
import NewsLetter from "../shared/Newsletter";
import HeroSection from '../components/Home/Hero'
import Destination from './Destinations'
import TestimonialPage from "./TestimonialsFromTrip";
import Social from "../components/Home/Social";
import BlogPosts from "./BlogPosts";
import FreeCancellation from "./FreeCancelation";
import EnatEthiopi from "../components/Header/EnatEthiopia";
import NewOfferings from "./Category/NewOfferings/NewOffering";
import Companies from "./Companies";
import AboutHero from "../components/Home/About-Hero";
import Recommendation from "../components/Featured-tours/Recommended";
const Home = () => {
  return (
    <>
      {/* ========== HERO SECTION ========== */}
      <HeroSection/>
      {/* <AboutHero/> */}
      <br /><br/>
      {/* <EnatEthiopi/> */}
    
      <Container >
  <Row>
   
    <FeaturedTourList />
   
  </Row>
  <br /><br />

</Container>

    
<Recommendation/>
     


      


      <Destination/>
     

      <TestimonialPage/>
      <br />

      <EnatEthiopi/>
      <FreeCancellation/>
    
<br /><br />
<NewOfferings/>
      <BlogPosts/>
      
      <WhyTravelPage/>
     
      {/* ========== TESTIMONIAL SECTION END ================== */}
      <NewsLetter />
      {/* <Social/> */}
      <Companies/>
    </>
  );
};

export default Home;
