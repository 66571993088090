// holidaysData.js
import timket1 from './images/IMG_8925.JPG'
import timket2 from './images/IMG_8924.JPG'
import timket3 from './images/IMG_8923.JPG'
import timket4 from './images/IMG_8922.JPG'
import timket5 from './images/IMG_8921.JPG'

import irecha1 from './images/IMG_3280.JPG'
import irecha2 from './images/IMG_3281.JPG'
import irecha3 from './images/IMG_3282.JPG'
import irecha4 from './images/IMG_3283.JPG'

import aschendye from './images/IMG_3269.JPG'

import fa1 from './images/fa1.JPG'
import fa2 from './images/fa2.JPG'


import gena1 from './images/gena1.webp';
import gena2 from './images/gena3.jpg';
import gena3 from './images/gena4.jpg';

import me1 from './images/me1.jpg';
import me2 from './images/me2.jpeg';
import me3 from './images/me3.webp';

import en1 from './images/enk1.jpg'

import en2 from './images/enku2.jpg'
const holidaysData = [
    {
      name: "Timkat (Epiphany)",
      description: "Timkat is the greatest festival of the year, commemorating Christ’s baptism in the Jordan River by John the Baptist.",
      details: "Timkat is celebrated on 19 January, just two weeks after the Ethiopian Christmas. The next day is devoted to the feast of St. Michael the Archangel. From the end of the rains in October, the country becomes increasingly dry and the sun blazes down from a clear blue sky, so the festival of Timkat always takes place in glorious weather.",
      coverImage: timket1,
      additionalImages: [timket1, timket2, timket3,timket4,timket5]
    },
    {
      name: "Ethiopian Christmas (Genna)",
      description: "Christmas in Lalibela is beautiful; it is colorfully celebrated because King Lalibela was born on the same day as the birth of Jesus Christ.",
      details: "Starting two weeks ahead of Gena, numerous peoples from every corner of the country undertake the pilgrimage to Lalibela to attend the ceremony and to get spiritual blessings.",
      coverImage: gena1,
      additionalImages: [gena1, gena2, gena3]
    },
    {
      name: "Meskel (The finding of true cross)",
      description: "Meskel is the commemoration feast of the finding of the True Holy Cross of Christ.",
      details: "Meskel was inscribed in the UNESCO Representative List of the Intangible Cultural Heritage of Humanity in 2013.",
      coverImage: me1,
      additionalImages: [me2, me3, me3]
    },
    {
      name: "FASIKA (Easter)",
      description: "Fasika (Easter) is celebrated after 55 days of severe Lent fasting (Hudade or Abye Tsome).",
      details: "According to Orthodox Tewahedo, Christians do not eat meat and dairy products for the whole 55 days. Vegetarian meals such as lentils, ground split peas, grains, fruit, and variations of vegetable stew accompanied by enjera and/or bread are only eaten on these days.",
      coverImage: fa1,
      additionalImages: [fa1,fa2]
    },
    {
      name: "Irecha",
      description: "Irecha means, according to Oromo’s, Thanksgiving day to their “Waqa“ or God.",
      details: "At the national level, it is celebrated in Bishoftu town in Oromyia region in Lake Hora Arsedi.",
      coverImage: irecha1,
      additionalImages: [irecha1, irecha2, irecha3,irecha4]
    },
    {
      name: "Ashendye",
      description: "Ashendye is a unique traditional festival which takes place in August to mark the ending of fasting called Filseta.",
      details: "This event is mostly for girls and young women, which they await eagerly every year. The name of the festival ‘Ashendye’ comes from the name of a tall grass that the girls make into a skirt and wear it around their waist as a decoration.",
      coverImage: aschendye,
      additionalImages: [aschendye]
    },
    {
      name: "Enkutatash (Ethiopian New Year)",
      description: "Enkutatash, which means “Gift of Jewels” is the celebration of the Ethiopian New Year.",
      details: "Ethiopia follows the Julian calendar, which consists of 13 months – 12 months each with 30 days and a final month with 5 days (6 days in leap year). The Julian calendar is 7 years and 8 months behind the Gregorian calendar, which is used throughout most of the Western world.",
      coverImage: en1,
      additionalImages: [en1,en2]
    }
  ];
  
  export default holidaysData;
  