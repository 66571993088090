import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { fetchTours } from "../../utils/API/ToursService";
import "./recommended.css";
import info1 from './im1.png'
import info2 from './im2.png'
import info3 from './im3.png'

function Recommendation() {
  const [active, setActive] = useState();
  const [tours, setTours] = useState([]);

  function truncateText(text, maxLength = 50) {
    if (text && text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  }
  
  useEffect(() => {
    async function fetchToursData() {
      try {
        const fetchedTours = await fetchTours();
        setTours(fetchedTours.data);
      } catch (error) {
        console.error("Error fetching tours:", error.message);
      }
    }

    fetchToursData();
  }, []);

  const packages = [
    "The Weekend Break",
    "The Package Holiday",
    "The Group Tour",
    "Long Term Slow Travel",
  ];

  return (
    <section id="recommendation" className="recommendation">
      {/* <div className="title">
        <h1>Recommend</h1>
        <div className="CategoryBar">
          <ul>
            {packages.map((pkg, index) => {
              return (
                <li
                  key={index}
                  className={active === index + 1 ? "Active" : ""}
                  onClick={() => setActive(index + 1)}>
                  {pkg}
                </li>
              );
            })}
          </ul>
        </div>
      </div> */}

      <div className="recommendationBox">
        {tours.slice(-6).map((item) => {
          return (
            <Link to={`/tours/${item._id}`} key={item._id}> {/* Wrap each card in Link */}
              <div className="box">
                <div className="image">
                  <img src={item.photo} alt="image" />
                </div>
                <h5>{item.title}</h5>
                <p>{truncateText(item.desc)}</p>

                <div className="price">
                  <div>
                    <img src={info1} alt="image" />
                    <img src={info2} alt="image" />
                    <img src={info3} alt="image" />
                  </div>

                  <p>${item.price}</p>
                </div>

                <div className="details">
                  <p>1500 kms</p>
                  <p>Approx-{item.itinerary.length}-day-trip</p>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </section>
  );
}

export default Recommendation;
