import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Explore, Flight, Hotel, NaturePeople, VerifiedUser, Eco, AccessibilityNew, EmojiNature,
  Business, People, Flag, Favorite, LocalOffer
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  card: {
    marginBottom: theme.spacing(3),
  },
  icon: {
    color: theme.palette.primary.main,
  },
  header: {
    marginBottom: theme.spacing(4),
    fontWeight: 'bold',
  },
  listItem: {
    paddingLeft: 0,
  },
  listItemIcon: {
    minWidth: theme.spacing(4),
  },
}));

const AboutUs = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography variant="h3" align="center" className={classes.header}>
        About Us
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Explore className={classes.icon} fontSize="large" />
                <Typography variant="h5" component="h2" style={{ marginLeft: 8 }}>
                  Enat Ethiopia Tours - Travel Agency
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                Embark on an extraordinary journey and unlock the hidden gems of Ethiopia, a land of breathtaking landscapes, ancient history, and vibrant cultures.
                Whether you’re a seasoned traveler or venturing into uncharted territory, exploring Ethiopia is an experience that will leave an indelible mark on your soul.
              </Typography>
              <Typography variant="body1" paragraph>
                But with so much to see and do, it can be overwhelming to plan your trip alone. That’s where a trusted Ethiopian travel agent comes in. With their expert knowledge and insider tips, they can craft a personalized itinerary that takes you off the beaten path and into the heart of this enchanting country.
              </Typography>
              <Typography variant="body1" paragraph>
                From the towering Simien Mountains to the mystical rock-hewn churches of Lalibela, every corner of Ethiopia has a story to tell. So, why settle for an ordinary trip when you can have an extraordinary adventure with the help of a trusted Ethiopian travel agent? Let us help you uncover the hidden treasures and create memories that will last a lifetime.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <People className={classes.icon} fontSize="large" />
                <Typography variant="h5" component="h2" style={{ marginLeft: 8 }}>
                  Our Story
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                Since our inception in 2018, Enat Ethiopia Touring has been offering a rich array of Ethiopia tours to showcase the heart and soul of this remarkable country. Our founder, Achenef Asnake, leverages his extensive experience and broad network in the Ethiopian tourism industry to customize unique journeys, ensuring every trip is as remarkable as our beloved nation.
              </Typography>
              <Typography variant="body1" paragraph>
                Enat Ethiopia Tours and Travel Agency is a privately-owned business organization of the tourist industry. We offer a wide assortment of organized tours to our extraordinarily interesting country. Upon request, we can organize any trip to Ethiopia, for any desired group, a company, or individual tourists. We always customize our proposals bearing in mind the needs of our customers.
              </Typography>
              <Typography variant="body1" paragraph>
                The company was established in the year 2018, but, despite that, it is already in the possession of the enormous store of experience in the Ethiopian market. There are few Ethiopian travel agencies with such a sustained experience. The president of the Corporation had been working in various capacities in the local enterprises of the tourist industry, namely as: manager, tour organizer and a pilot. In every corner of Ethiopia, he has friends, serving him with advice and assistance.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Business className={classes.icon} fontSize="large" />
                <Typography variant="h5" component="h2" style={{ marginLeft: 8 }}>
                  Our Mission
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                Enat Ethiopia Tours and Travel Agency ensures the highest quality at the lowest possible price. We are a perfectly organized business organization, having at its disposal educated and experienced personnel. Thanks to that, we can ensure unforgettable impressions.
              </Typography>
              <Typography variant="body1" paragraph>
                Enat Ethiopia Tours And Travel is devoted to delivering unforgettable Ethiopia tours and contributing to Ethiopia’s economic growth and job creation. We are passionate about fostering sustainability within the travel sector, and our goal is to craft sustainable itineraries that not only cater to our international clients but also promote sustainable practices amongst our local suppliers.
              </Typography>
              <Typography variant="body1" paragraph>
                Our commitment extends to environmental conservation, and the health and safety of our employees, customers, and the local communities where we operate.
              </Typography>
              <List>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Eco className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Environmental Conservation" />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <AccessibilityNew className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Health and Safety" />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Favorite className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Customer Satisfaction" />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Flag className={classes.icon} fontSize="large" />
                <Typography variant="h5" component="h2" style={{ marginLeft: 8 }}>
                  Why Choose Us?
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                At our disposal, there is camping equipment and comfortable four-wheel drive off-road vehicles. It makes it possible for us to visit with our tourists all, even the most inaccessible attractions of Ethiopia.
              </Typography>
              <Typography variant="body1" paragraph>
                Enat Ethiopia Tour And Travel Agency collaborates with the best business organizations, active in the Ethiopian tourist market: airline companies, Ethiopian tourist agencies, hotels, car rental companies, and with the managements of national parks and nature reserves, museums, churches, and mosques.
              </Typography>
              <Typography variant="body1" paragraph>
                We are acquainted with the local culture and follow the local customs. For that very reason, our customers are everywhere sure of a cordial welcome, and they make new friends no matter where they go.
              </Typography>
              <List>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Flight className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Expert Knowledge" />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Hotel className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Top-notch Accommodations" />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <NaturePeople className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Authentic Experiences" />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <VerifiedUser className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Safety and Comfort" />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <LocalOffer className={classes.icon} fontSize="large" />
                <Typography variant="h5" component="h2" style={{ marginLeft: 8 }}>
                  Our Services
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                We offer a wide assortment of organized tours to our extraordinarily interesting country. Upon request, we can organize any trip to Ethiopia, for any desired group, a company, or individual tourists. We always customize our proposals bearing in mind the needs of our customers.
              </Typography>
              <Typography variant="body1" paragraph>
                Ethiopia is a large country offering an incredible variety of natural, cultural, and historical attractions. Its uniquely diversified climatic and landscape conditions make it a habitat for numerous animals. Especially the population of birds, many of which can only be found in Ethiopia, is excessively big. After rain, the soil in Ethiopia is ornamented with a ‘carpet’ of colorful flowers. Also, the number of endemic plants is bigger than in most African countries.
              </Typography>
              <List>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <EmojiNature className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Adventure Tours" />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Flight className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Cultural Tours" />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Hotel className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Hotel Bookings" />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <NaturePeople className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Custom Itineraries" />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutUs;
