import React from 'react'
import TourCard from '../../shared/TourCard'
// import tourData from '../../assets/data/tours'
import { Col, Button } from 'reactstrap'
import useFetch from './../../hooks/useFetch'
import { BASE_URL } from './../../utils/config'
import { Link } from 'react-router-dom'
import { FaGem } from 'react-icons/fa'


const FeaturedTourList = () => {
   const {data: featuredTours, loading, error} = useFetch(`${BASE_URL}/tours/search/getFeaturedTour`)
   console.log(featuredTours)
   

   return (
      <>
       <hr className="my-4 border-b-4 border-gray-700" style={{ borderWidth: '2px' }} />

      <Col lg="12" className='text-center'>
  <h1 className="mb-3" style={{ marginBottom: "10px", color: "#333" }}>Explore Enat Ethiopian Tours</h1>
  <h2 className="featured__tour-title" style={{ marginTop: "0", color: "#555" }}>Our featured tours</h2>
  
</Col>

         { loading && <h4>Loading.....</h4> }
         { error && <h4>{error}</h4> }
         {
            !loading && !error &&
            featuredTours?.map(tour => (
               <Col lg='3' md='4' sm='6' className='mb-4' key={tour._id}>
                  
                  <TourCard tour={tour} />
               </Col>
            ))
         }

<Col lg="12" className='text-center mt-4'>
    <Link to="/tours" className="text-decoration-none">
        <Button color="primary" size="lg" className="flex items-center"> {/* Set size to large and use flexbox */}
            {/* Add icon with a margin */}
            <div className="flex items-center">
      <FaGem className="mr-2" />
      <span>Explore More Tours</span>
    </div>
        </Button>
    </Link>
</Col>

        
      </>
   )
}

export default FeaturedTourList 