import React, { useState, useEffect } from 'react';
import { fetchToursByType } from '../../utils/API/ToursService';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import TourCard from '../../shared/TourCard';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const DayTripsAndExcursions = () => {
  const [loading, setLoading] = useState(true);
  const [tours, setTours] = useState([]);

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const tourType = "Day Trips & Excursions";
        const fetchedTours = await fetchToursByType(tourType);
        setTours(fetchedTours);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tours:', error.message);
        setLoading(false);
      }
    };

    fetchTours();
  }, []);

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-6">Day Trips & Excursions</h1>
      {loading ? (
        <div className="flex items-center justify-center h-64">
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {tours.map((tour) => (
            <TourCard key={tour._id} tour={tour} />
          ))}
        </div>
      )}
    </div>
  );
};

export default DayTripsAndExcursions;
