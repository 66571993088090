import React, { useState } from "react";
import { Card, Avatar, Rate } from "antd";
import { FaUser, FaGlobe, FaRegCalendarAlt, FaAngleDown, FaAngleUp, FaExternalLinkAlt  } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const { Meta } = Card;

const testimonials = [
  {
    id: 1,
    userName: "MIchelle G",
    userAvatar: "https://play-lh.googleusercontent.com/a-AMeVztkMsBPUD7uzJDb9sqUfm-1w8NhFxlGz746BJhiNkaD7vedpgJJeXULS_nrgI4",
    country: "USA",
    date: "2024-03-12",
    rating: 5,
    comment: "I can’t say enough about Ashu, and the tour we received while stopping over on a layover in Ethiopia. He took us up to Mount Entoto and we were able to see the city on the way up there. What a great experience. We even stopped and picked up some amazing coffee recommended by Ashu. His knowledge and passion for being an outstanding tour guide and in his country of Ethiopia is clear. Even if you have 4 hours to spare when passing through the country, reach out, you will be glad you did!"
  },
  {
    id: 2,
    userName: "DAVID B",
    userAvatar: "https://play-lh.googleusercontent.com/a-AMeVztkMsBPUD7uzJDb9sqUfm-1w8NhFxlGz746BJhiNkaD7vedpgJJeXULS_nrgI4",
    country: "Israel",
    date: "2024-03-12",
    rating: 5,
    comment: "One of the best guide in Addis Ababa and we enjoyed the trip with him, we feel comfortable and connected with his spirit and loved the service,we definitely we will be back and book Enat tours."
  },
  {
    id: 3,
    userName: "Zhou",
    userAvatar: "https://play-lh.googleusercontent.com/a-AMeVztkMsBPUD7uzJDb9sqUfm-1w8NhFxlGz746BJhiNkaD7vedpgJJeXULS_nrgI4",
    country: "Japan",
    date: "2024-03-12",
    rating: 5,
    comment: "What a magical adventure in Dankile depression and volcano lifetime experience with my ake rasta, such an amazing professionalism and unlimited hospitality with a huge geological! Thank you so much for all ,100%reccomanded enat tours."
  },
  {
    id: 4,
    userName: "Simon A",
    userAvatar: "https://play-lh.googleusercontent.com/a-AMeVztkMsBPUD7uzJDb9sqUfm-1w8NhFxlGz746BJhiNkaD7vedpgJJeXULS_nrgI4",
    country: "The Netherlands",
    date: "2024-03-12",
    rating: 5,
    comment: `We have booked for a half day city touring with Enat Tours as we were having only for a couple of hours layout in Addis.

    Ashu was very informative and quick to settled down our inquiries ,Airport pick up on time ,and his a real historical and friendly guide ,his passionate about his country is amazing ,we feel that we were watching a TV channel but he was Ash ,knowledge flows like a magical water falls .
    
    A super start talnted guide we could find,Keep rocking Ash brother .`
  }
  
];

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "block", left: "-30px", zIndex: "1" }} onClick={onClick}>
      <span className="text-3xl">&#10094;</span>
    </div>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "block", right: "-30px", zIndex: "1" }} onClick={onClick}>
      <span className="text-3xl">&#10095;</span>
    </div>
  );
};

const TestimonialPage = () => {
  const [expandedTestimonials, setExpandedTestimonials] = useState([]);

  const toggleExpansion = (id) => {
    if (expandedTestimonials.includes(id)) {
      setExpandedTestimonials(expandedTestimonials.filter((item) => item !== id));
    } else {
      setExpandedTestimonials([...expandedTestimonials, id]);
    }
  };

  const isExpanded = (id) => {
    return expandedTestimonials.includes(id);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const getCharCount = (text) => {
    return text.length;
  };

  return (
    <div className="testimonial-container p-6">
      <h1 className="testimonial-heading text-center text-2xl font-bold text-green-700 mb-6">Testimonials from TripAdvisor</h1>
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="">
            <Card hoverable className="w-full  bg-gray-100 mx-0.5 mb-4 border border-gray-200 rounded-md shadow-md" style={{ width: "calc(100% - 6px)" }}>
              <Meta
                avatar={<Avatar src={testimonial.userAvatar} />}
                title={
                  <>
                    <FaUser className="text-green-700 mr-1" /> <span className="font-semibold">{testimonial.userName}</span>
                  </>
                }
                description={
                  <>
                    <Rate disabled defaultValue={testimonial.rating} />
                    <p className={`text-sm mt-2 ${isExpanded(testimonial.id) || getCharCount(testimonial.comment) <= 150 ? "" : "truncate"}`}>
                      {testimonial.comment}
                    </p>
                    {getCharCount(testimonial.comment) > 150 && (
                      <span className="cursor-pointer text-blue-600" onClick={() => toggleExpansion(testimonial.id)}>
                        {isExpanded(testimonial.id) ? (
                          <>
                            <FaAngleUp className="mr-1" /> Read less
                          </>
                        ) : (
                          <>
                            <FaAngleDown className="mr-1" /> Read more
                          </>
                        )}
                      </span>
                    )}
                    <div className="flex items-center mt-2">
                      <FaGlobe className="text-green-700 mr-1" /> <span className="font-semibold">{testimonial.country}</span> | <FaRegCalendarAlt className="text-green-700 ml-1 mr-1" /> <span className="font-semibold">{testimonial.date}</span>
                    </div>
                    <div className="mt-2">
  <a href="https://www.tripadvisor.com/Attraction_Review-g293791-d16644741-Reviews-Enat_Ethiopia_Tours_Travel-Addis_Ababa.html" className="text-blue-600" target="_blank" rel="noopener noreferrer">
    See on Trip Advisor. <FaExternalLinkAlt className="ml-1" />
  </a>
</div>
                  </>
                }
              />
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialPage;

