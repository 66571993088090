import React, { useState, useEffect } from 'react';
import { Input, Button, Modal, Pagination, Table, Typography } from 'antd';
import { FaSearch, FaTrash, FaPlus } from 'react-icons/fa';
import { fetchDestinations, deleteDestination } from '../../../utils/API/destinationService';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { Link } from 'react-router-dom';

const { confirm } = Modal;
const { Title, Text } = Typography;

const AdminDestination = () => {
  const [destinations, setDestinations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchAllDestinations();
  }, [currentPage]);

  const fetchAllDestinations = async () => {
    try {
      const fetchedDestinations = await fetchDestinations();
      setDestinations(fetchedDestinations);
    } catch (error) {
      console.error('Error fetching destinations:', error.message);
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const handleDeleteDestination = async (id) => {
    try {
      const token = user.token;
      await deleteDestination(id, token);
      fetchAllDestinations();
    } catch (error) {
      console.error('Error deleting destination:', error.message);
    }
  };

  const showDeleteConfirm = (id, name) => {
    confirm({
      title: `Are you sure you want to delete ${name}?`,
     
      content: 'This action cannot be undone',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteDestination(id);
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: '25%',
    },
    {
      title: 'Climate',
      dataIndex: 'climate',
      key: 'climate',
      width: '25%',
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      key: 'actions',
      render: (id, record) => (
        <Button type="danger" onClick={() => showDeleteConfirm(id, record.name)} icon={<FaTrash  className='text-red-500'/>} />
      ),
    },
  ];

  const filteredDestinations = destinations.filter(destination =>
    destination.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const pageSize = 6;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="max-w-screen-lg mx-auto mt-8">
        <div className="flex justify-between items-center mb-4">
        <Link to="/destination/add" className="flex items-center">
          <Button type="primary" icon={<FaPlus />} className="mr-2 bg-blue-500">Add Destination</Button>
          
        </Link>
      </div>
      <div className="mb-4">
       
        <Input
          placeholder="Search destinations"
          prefix={<FaSearch />}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      
      <Table
        dataSource={filteredDestinations.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
        columns={columns}
        rowKey="_id"
        pagination={false}
        rowClassName={(record, index) => index % 2 === 0 ? 'bg-blue-100' : 'bg-gray-100'}
        style={{ minWidth: '800px' }}
      />
      <div className="mt-4 flex justify-center">
        <Pagination
          current={currentPage}
          total={filteredDestinations.length}
          pageSize={pageSize}
          onChange={onPageChange}
        />
      </div>
      <br /><br />
    </div>
  );
};

export default AdminDestination;
