import React from "react";
import { Row, Col, Divider, Typography, Card } from "antd";
import {
  CompassOutlined,
  SafetyCertificateOutlined,
  WarningOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  CarOutlined,
  MedicineBoxOutlined,
  TeamOutlined,
} from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const { Meta } = Card;

const TermsAndConditionsPage = () => {
  return (
    <div className="container mx-auto px-8 py-12">
      <Title level={2}>Terms and Conditions</Title>
      
      <Divider />

      {/** Section 1: Booking */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card hoverable>
            <Meta
              avatar={<CompassOutlined style={{ fontSize: "24px", color: "#1890ff" }} />}
              title={<Title level={4}>1. Booking</Title>}
              description={
                <>
                  <Paragraph><b>1.1</b> Bookings will be effective after confirmation by Enat Ethiopia Tours.</Paragraph>
                  <Paragraph><b>1.2</b> Before confirmation of the booking by Enat Ethiopia Tours the client has to agree with the offered itinerary, the total tour price, and the Terms and Conditions as provided.</Paragraph>
                </>
              }
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      {/** Section 2: Payment */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card hoverable>
            <Meta
              avatar={<CarOutlined style={{ fontSize: "24px", color: "#1890ff" }} />}
              title={<Title level={4}>2. Payment</Title>}
              description={
                <>
                  <Paragraph><b>2.1</b> At the time of confirmation a 20% deposit of the total confirmed tour price is required.</Paragraph>
                  <Paragraph><b>2.2</b> The remaining 80% of the total confirmed tour price is payable before or upon arrival, prior to the commencement of the confirmed tour but unfortunately we don’t accept credit cards!</Paragraph>
                  <Paragraph><b>2.3</b> If complete payment has not been made as per above Enat Ethiopia Tours is not obliged to conduct the tour.</Paragraph>
                </>
              }
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      {/** Section 3: Price adjustment */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card hoverable>
            <Meta
              avatar={<ClockCircleOutlined style={{ fontSize: "24px", color: "#1890ff" }} />}
              title={<Title level={4}>3. Price adjustment</Title>}
              description={
                <>
                  <Paragraph><b>3.1</b> Enat Ethiopia Tours PLC reserves the right to adjust the total confirmed tour price in case of any unforeseen price increment after confirmation of the booking up to 49 days before the start of the tour. The client has the right to reject this increment in which case Enat Ethiopia Tours PLC will cancel the confirmed booking and in such an event only agrees to refund all moneys already paid and received from the client.</Paragraph>
                </>
              }
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      {/** Section 4: Cancellation */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card hoverable>
            <Meta
              avatar={<CloseCircleOutlined style={{ fontSize: "24px", color: "#ff4d4f" }} />}
              title={<Title level={4}>4. Cancellation</Title>}
              description={
                <>
                  <Paragraph><b>4.1</b> Any cancellation of a confirmed booking must be done in writing. Cancellations are subject to the following charges:</Paragraph>
                  <Paragraph><b>49 days or more prior to departure:</b> a handling fee of 150 USD.</Paragraph>
                  <Paragraph><b>29 days to 48 days prior to departure:</b> 20% of the total confirmed tour price</Paragraph>
                  <Paragraph><b>8 days to 28 days prior to departure:</b> 50% of the total confirmed tour price</Paragraph>
                  <Paragraph><b>7 days or less prior to departure:</b> 100% of the total confirmed tour price</Paragraph>
                  <Paragraph>International flight ticket cancellation per a flight will be 300</Paragraph>
                  <Paragraph><b>4.2</b> Special cancellation fees may apply for third-party bookings</Paragraph>
                  <Paragraph><b>4.3</b> Enat Ethiopia Tours PLC reserves the right to cancel the tour without prior notification in case of circumstances beyond its control and in such an event only agrees to refund all money already paid and received from the client.</Paragraph>
                </>
              }
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      {/** Section 5: Changes and Alterations */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card hoverable>
            <Meta
              avatar={<WarningOutlined style={{ fontSize: "24px", color: "#ff7a45" }} />}
              title={<Title level={4}>5. Changes and Alterations</Title>}
              description={
                <>
                  <Paragraph><b>5.1</b> Enat Ethiopia Tours PLC shall be entitled to, and may in its sole and absolute discretion, vary or cancel any itinerary or arrangement in relation to the tour due to any circumstances (including, but not limited to, war or threat of war, sabotage, civil disturbance, quarantine, government intervention, weather conditions or other unexpected occurrences) beyond the reasonable control of Enat Ethiopia Tours PLC.</Paragraph>
                  <Paragraph><b>5.2</b> In the unlikely event of a vehicle breakdown that is beyond the control of Enat Ethiopia Tours PLC and beyond immediate repair, Enat Ethiopia Tours PLC will arrange alternative means of transport in the fastest possible time. Enat Ethiopia Tours PLC reserves the right to alter the itinerary accordingly in order to make up for any time lost due to unforeseen circumstances.</Paragraph>
                  <Paragraph><b>5.3</b> No refunds are given for circumstances arising beyond Enat Ethiopia Tours PLC’s control.</Paragraph>
                  <Paragraph><b>5.4</b> Changes and alterations requested by the client(s) are, within reason, handled free of charge by Enat Ethiopia Tours PLC. Any additional cost by third-party bookings arising from these changes or alterations is payable by the client.</Paragraph>
                </>
              }
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      {/** Section 6: Liability */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card hoverable>
            <Meta
              avatar={<SafetyCertificateOutlined style={{ fontSize: "24px", color: "#52c41a" }} />}
              title={<Title level={4}>6. Liability</Title>}
              description={
                <>
                  <Paragraph><b>6.1</b> The client fully understands the risk inherent in traveling to a developing country and undertakes the tour at his/her own risk. The client is medically fit, in good physical and mental health, and is able to embark on the tour. The client agrees and concedes that Enat Ethiopia Tours PLC, its representatives, and its employees shall not be responsible for loss or damage to possessions, injury or illness to the client, or loss of life or consequential damages which might occur from any cause whatsoever.</Paragraph>
                  <Paragraph><b>6.2</b> Enat Ethiopia Tours only acts as an agent for its client in all matters relating to the providers of accommodation, any means of transport, and other third-party suppliers, and all bookings will be subject to the Terms and Conditions of the third-party supplier. Enat Ethiopia Tours cannot be held liable for injury, death, damage, loss, accident, or delay that may be caused by the third-party supplier.</Paragraph>
                  <Paragraph><b>6.3</b> Enat Ethiopia Tours PLC’s liability to passengers carried in its own vehicles is governed by the laws of Ethiopia and all claims are subject to the jurisdiction of the court of Ethiopia in which the cause of action arises.</Paragraph>
                  <Paragraph><b>6.4</b> Enat Ethiopia Tours PLC reserves the right to employ subcontractors to carry out any or all parts of the services agreed to be supplied and in any event of the exercise of such right the terms of clause 6.3 relating to Enat  Ethiopia Tours PLC’s liability shall apply.</Paragraph>
                  <Paragraph><b>6.5</b> The carriage and storage of all baggage and personal effects are at all times the client’s risk and Enat  Ethiopia Tours PLC will not accept any liability for any loss or damage of baggage or personal effects.</Paragraph>
                </>
              }
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      {/** Section 7: Insurance */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card hoverable>
            <Meta
              avatar={<MedicineBoxOutlined style={{ fontSize: "24px", color: "#faad14" }} />}
              title={<Title level={4}>7. Insurance</Title>}
              description={
                <>
                  <Paragraph><b>7.1</b> All insurance is solely the responsibility of the client. Before a client commences a tour, he/she should arrange his/her own insurance with protection for the full duration of the travel, to cover personal injury, medical expenses, repatriation expenses, and loss or damage of luggage. If a client falls ill, all hospital expenses, medical expenses, doctor’s fees, repatriation costs, etc. are the client’s responsibility and Enat Ethiopia Tours PLC shall not be liable for any refund of the confirmed tour price whatsoever. Insurance to cover the cancellation of the confirmed tour is strongly recommended.</Paragraph>
                </>
              }
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      {/** Section 8: Enat Ethiopia Tours PLC Responsibility and Rights */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card hoverable>
            <Meta
              avatar={<TeamOutlined style={{ fontSize: "24px", color: "#722ed1" }} />}
              title={<Title level={4}>8. Enat Ethiopia Tours PLC Responsibility and Rights</Title>}
              description={
                <>
                  <Paragraph><b>8.1</b> Enat Ethiopia Tours will make every possible effort to operate a tour according to the confirmed itinerary and the confirmed total tour price and will try to substitute any amendment with the same or better quality if possible.</Paragraph>
                  <Paragraph><b>8.2</b> The information confirmed by Enat Ethiopia Tours PLC is given in good faith and is based on the latest information available and serves as a guideline only. Enat Ethiopia Tours PLC reserves the right to change the facilities or transport described without being liable for any compensation or refund.</Paragraph>
                </>
              }
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      {/** Section 9: Enat Ethiopia Tours PLC Authority */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card hoverable>
            <Meta
              avatar={<WarningOutlined style={{ fontSize: "24px", color: "#faad14" }} />}
              title={<Title level={4}>9. Enat Ethiopia Tours PLC Authority</Title>}
              description={
                <>
                  <Paragraph><b>9.1</b> Any decision made by the Enat Ethiopia Tours PLC representative on the tour shall be deemed final on all matters.</Paragraph>
                  <Paragraph><b>9.2</b> Enat Ethiopia Tours PLC shall not be responsible or liable for any client who commits an illegal or unlawful act, or the consequences of such an illegal act. The client may in such circumstances be excluded from the tour without a refund, at the sole discretion of Enat Ethiopia Tours PLC.</Paragraph>
                  <Paragraph><b>9.3</b> If Enat Ethiopia Tours PLC considers a client unsuitable for a tour (due to mental or physical illness or implied danger to any other client or Enat Ethiopia Tours PLC representative), before or during the tour, it may at its sole discretion decline to carry the client further without any refund whatsoever.</Paragraph>
                </>
              }
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      {/** Section 10: Client Responsibility and Rights */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card hoverable>
            <Meta
              avatar={<WarningOutlined style={{ fontSize: "24px", color: "#faad14" }} />}
              title={<Title level={4}>10. Client Responsibility and Rights</Title>}
              description={
                <>
                  <Paragraph><b>10.1</b> The onus is on the client concerned to fully acquaint themselves with the Terms and Conditions set herein and all matters such as visas, required documentation, payment schedules, cancellation policies, and liability.</Paragraph>
                  <Paragraph><b>10.2</b> It is the client’s sole responsibility to ensure that passport, visa, permits, and immunizations required for the tour, are obtained well in advance and are in order for a visit to Ethiopia. Enat Ethiopia Tours PLC cannot be held liable for any failure or additional cost on the part of a client to be in possession of the correct travel documentation.</Paragraph>
                  <Paragraph><b>10.3</b> In the unlikely event that the client has a complaint against Enat Ethiopia Tours PLC, the company must be informed immediately, in order that an opportunity is afforded to investigate the situation and provide redress.</Paragraph>
                </>
              }
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      {/** Section 11: Disputes */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card hoverable>
            <Meta
              avatar={<WarningOutlined style={{ fontSize: "24px", color: "#faad14" }} />}
              title={<Title level={4}>11. Disputes</Title>}
              description={
                <>
                  <Paragraph><b>11.1</b> Any dispute arising out of these Terms and Conditions is subject to the laws of Ethiopia.</Paragraph>
                </>
              }
            />
          </Card>
        </Col>
      </Row>

      <Divider />

    </div>
  );
};

export default TermsAndConditionsPage;
