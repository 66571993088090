import React, { useState, useEffect } from 'react';
import { Input, Button, Modal, Pagination, Table, Typography, Tag } from 'antd';
import { FaSearch, FaTrash, FaEdit, FaPlus } from 'react-icons/fa';
import { fetchBlogPosts, deleteBlogPost } from '../../../utils/API/blogPostService';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { Link } from 'react-router-dom';
import moment from 'moment';

const { confirm } = Modal;
const { Title, Text } = Typography;

const AdminBlog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchAllBlogPosts();
  }, [currentPage]);

  const fetchAllBlogPosts = async () => {
    try {
      const fetchedBlogPosts = await fetchBlogPosts();
      setBlogPosts(fetchedBlogPosts);
    } catch (error) {
      console.error('Error fetching blog posts:', error.message);
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const handleDeleteBlogPost = async (id) => {
    try {
      const token = user.token;
      await deleteBlogPost(id, token);
      fetchAllBlogPosts();
    } catch (error) {
      console.error('Error deleting blog post:', error.message);
    }
  };

  const showDeleteConfirm = (id, title) => {
    confirm({
      title: `Are you sure you want to delete "${title}"?`,
      content: 'This action cannot be undone',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteBlogPost(id);
      },
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
      width: '20%',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      render: (date) => moment(date).format('MMMM Do, YYYY')
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: '20%',
      render: tags => (
        <Tag color="blue">{tags.length > 0 ? tags[0] : 'No Tag'}</Tag>
      ),
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      key: 'actions',
      render: (id, record) => (
        <span>
          <Button type="primary" icon={<FaEdit />} className="mr-2 bg-blue-500" />
          <Button type="danger" onClick={() => showDeleteConfirm(record._id, record.title)} icon={<FaTrash className='bg-white-500' />} className='bg-red-500' />
        </span>
      ),
    },
  ];

  const filteredBlogPosts = blogPosts.filter(post =>
    post.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const pageSize = 6;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="max-w-screen-lg mx-auto mt-8">
      <div className="flex justify-between items-center mb-4">
        <Link to="/blog/add" className="flex items-center">
          <Button type="primary" icon={<FaPlus />} className="mr-2 bg-blue-500">Add Post</Button>
        </Link>
        <Input
          placeholder="Search blog posts"
          prefix={<FaSearch />}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <Table
        dataSource={filteredBlogPosts.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
        columns={columns}
        rowKey="_id"
        pagination={false}
        rowClassName={(record, index) => index % 2 === 0 ? 'bg-blue-100' : 'bg-gray-100'}
        style={{ minWidth: '800px' }}
      />
      <div className="mt-4 flex justify-center">
        <Pagination
          current={currentPage}
          total={filteredBlogPosts.length}
          pageSize={pageSize}
          onChange={onPageChange}
        />
      </div>
      <br /><br />
    </div>
  );
};

export default AdminBlog;
