import React from 'react';

const EnatEthiopi = () => {
  return (
    <div className="container mx-auto px-2 ">
       <h1 className="text-3xl text-center font-bold mb-6">Why Choose Enat Ethiopia Tours ?</h1>
       <br /><br />
      <div className="flex flex-col md:flex-row items-center justify-center md:justify-start md:space-x-36">
        <div className="flex flex-col items-center  mb-8 md:mb-0 md:mr-0 md:ml-auto">
          <span className="text-4xl mb-6">🔄</span>
          <p className="text-lg font-bold mb-4">Free cancellation</p>
          <p className="text-sm">Stay flexible on your trip.</p>
        </div>
        <div className="flex flex-col items-center mb-8 md:mb-0">
          <span className="text-4xl mb-6">🌍</span>
          <p className="text-lg font-bold mb-4">2000+ experiences</p>
          <p className="text-sm">Make memories around the world.</p>
          <p className="text-sm">Reserve now, pay later</p>
        </div>
        <div className="flex flex-col items-center mb-8 md:mb-0">
          <span className="text-4xl mb-6">💳</span>
          <p className="text-lg font-bold mb-4">Reserve now, pay later</p>
          <p className="text-sm">Book your spot.</p>
        </div>
        <div className="flex flex-col items-center mb-8 md:mb-0 md:ml-0 md:mr-auto">
          <span className="text-4xl mb-6">🌟</span>
          <p className="text-lg font-bold mb-4">Trusted reviews</p>
          <p className="text-sm">5.0 stars from 300+ Trip Advisors reviews.</p>
        </div>
      </div>
    </div>
  );
};

export default EnatEthiopi;
