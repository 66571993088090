import React from 'react';
import { FacebookOutlined, WhatsAppOutlined, InstagramOutlined, LinkedinOutlined, TwitterOutlined } from '@ant-design/icons';
import logo from '../../assets/images/logo.png';

const SecondHeader = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center py-2 px-6 md:px-12 lg:px-24">
      {/* Left side: Logo */}
      <div className="logo mb-4 md:mb-0 md:mr-8 flex-shrink-0">
        <img src="https://i.ibb.co/19fSSnz/enat-ethiopia.jpg" alt="Logo" className="w-64 md:w-32 h-auto" />
      </div>
      
      {/* Right side: Social Media Icons */}
      <div className="flex items-center space-x-4 md:space-x-8 flex-wrap md:flex-nowrap">
        <a href="https://www.facebook.com/EnatEthiopiaTours/" target="_blank" rel="noopener noreferrer" className="bg-gray-200 rounded-full p-2">
          <FacebookOutlined style={{ fontSize: '24px', color: '#3b5998' }} />
        </a>
        <a href="https://wa.me/251923654512" target="_blank" rel="noopener noreferrer" className="bg-gray-200 rounded-full p-2">
          <WhatsAppOutlined style={{ fontSize: '24px', color: '#25D366' }} />
        </a>
        <a href="https://www.instagram.com/enat_ethiopia_tours/" target="_blank" rel="noopener noreferrer" className="bg-gray-200 rounded-full p-2">
          <InstagramOutlined style={{ fontSize: '24px', color: '#E4405F' }} />
        </a>
        <div className="bg-gray-200 rounded-full p-2">
          <LinkedinOutlined style={{ fontSize: '24px', color: '#0077B5' }} />
        </div>
        <a href="https://twitter.com/achenefasnake" target="_blank" rel="noopener noreferrer" className="bg-gray-200 rounded-full p-2">
          <TwitterOutlined style={{ fontSize: '24px', color: '#1DA1F2' }} />
        </a>
      </div>
    </div>
  );
};

export default SecondHeader;
