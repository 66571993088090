import React, { useState } from 'react';
import { Form, Input, Button, Upload, message, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { createBlogPost } from '../../utils/API/blogPostService';
import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';

const BlogForm = () => {
  const [form] = Form.useForm();

  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const {user}=useContext(AuthContext)
  
  const handleImageDelete = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const handleVideoDelete = (index) => {
    const newVideos = [...videos];
    newVideos.splice(index, 1);
    setVideos(newVideos);
  };

  const onFinish = async (values) => {
    try {
      const blogPostData = {
        title: values.title,
        content: values.content,
        category: values.category,
        tags: values.tags.split(",").map(tag => tag.trim()),
       
        images: images,
        videos: videos,
      };
      const token=user.token;
      await createBlogPost(blogPostData,token);
      message.success('Blog post created successfully!');
      form.resetFields(); // Reset form fields after successful submission
    } catch (error) {
      console.error('Error creating blog post:', error);
      message.error('Failed to create blog post');
    }
  };

  return (
    <Card title="Create New Blog Post" style={{ maxWidth: '600px', margin: 'auto' }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Please enter the title' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="content"
          label="Content"
          rules={[{ required: true, message: 'Please enter the content' }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true, message: 'Please enter the category' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="tags"
          label="Tags"
          rules={[{ required: true, message: 'Please enter at least one tag' }]}
        >
          <Input />
        </Form.Item>

        
        <Form.Item
  name="images"
  label="Images"
>
  <Upload
    multiple
    beforeUpload={(file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "hq840p7x"); // Replace "your_upload_preset" with your Cloudinary upload preset
      formData.append("folder", "blog_images");
      formData.append("tags", "blog_image");
      formData.append("resource_type", "image");

      fetch(
        "https://api.cloudinary.com/v1_1/dx9lkfhua/image/upload", // Replace "your_cloud_name" with your Cloudinary cloud name
        {
          method: "POST",
          body: formData,
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to upload image');
          }
          return response.json();
        })
        .then((data) => {
          if (data.secure_url) {
            console.log("Uploaded image:", data.secure_url);
            const newImages = [...images, data.secure_url];
            setImages(newImages);
          } else {
            throw new Error('Secure URL not found in response');
          }
        })
        .catch((error) => {
          console.error('Error uploading image:', error);
          message.error('Failed to upload image');
        });

      return false; // Prevent default upload behavior
    }}
    showUploadList={false}
  >
    <Button icon={<PlusOutlined />}>Upload</Button>
  </Upload>

  {images.map((image, index) => (
    <div key={index} style={{ marginTop: '10px' }}>
      <img src={image} alt={`image-${index}`} style={{ maxWidth: '100px' }} />
      <Button danger style={{ marginLeft: '10px' }} onClick={() => handleImageDelete(index)}>Delete</Button>
    </div>
  ))}
</Form.Item>

<Form.Item
  name="videos"
  label="Videos"
>
  <Upload
    multiple
    beforeUpload={(file) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "your_upload_preset"); // Replace "your_upload_preset" with your Cloudinary upload preset
      formData.append("folder", "blog_videos");
      formData.append("tags", "blog_video");
      formData.append("resource_type", "video");

      fetch(
        "https://api.cloudinary.com/v1_1/your_cloud_name/video/upload", // Replace "your_cloud_name" with your Cloudinary cloud name
        {
          method: "POST",
          body: formData,
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to upload video');
          }
          return response.json();
        })
        .then((data) => {
          if (data.secure_url) {
            console.log("Uploaded video:", data.secure_url);
            const newVideos = [...videos, data.secure_url];
            setVideos(newVideos);
          } else {
            throw new Error('Secure URL not found in response');
          }
        })
        .catch((error) => {
          console.error('Error uploading video:', error);
          message.error('Failed to upload video');
        });

      return false; // Prevent default upload behavior
    }}
    showUploadList={false}
  >
    <Button icon={<PlusOutlined />}>Upload</Button>
  </Upload>

  {videos.map((video, index) => (
    <div key={index} style={{ marginTop: '10px' }}>
      <video controls style={{ maxWidth: '100%' }}>
        <source src={video} type="video/mp4" />
      </video>
      <Button danger style={{ marginLeft: '10px' }} onClick={() => handleVideoDelete(index)}>Delete</Button>
    </div>
  ))}
</Form.Item>


        <Form.Item>
          <Button type="primary" htmlType="submit" className='bg-blue-500'>Submit</Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default BlogForm;
