import React, { useEffect, useState } from 'react';
import { Card, Typography, Row, Col, Divider, Button, Carousel } from 'antd';
import { useParams } from 'react-router-dom';
import { TiWeatherPartlySunny } from 'react-icons/ti';
import { FaRegImage, FaMapMarkerAlt } from 'react-icons/fa';
import { BiCheck, BiTimeFive } from 'react-icons/bi';
import { GrDocumentText } from 'react-icons/gr';
import { MdLocalActivity } from 'react-icons/md';
import { fetchDestinationById } from '../utils/API/destinationService';
import TourSEO from './TourSEO';


const { Title, Text } = Typography;

const DestinationDetails = () => {
  const { id } = useParams();
  const [destination, setDestination] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDestinationById(id);
        setDestination(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!destination) {
    return <div>Destination not found</div>;
  }

  return (
    <div className="container mx-auto py-8">
      <TourSEO metaTitle={destination.metaTitle} metaDescription={destination.metaDescription} keywords={destination.keywords} />
      <Title level={2} className="text-3xl mb-4">{destination.name}</Title>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card bordered={false} className="shadow-lg rounded-lg">
            <Title level={4}>Overview</Title>
            <div dangerouslySetInnerHTML={{ __html: destination.description }}></div>
          </Card>
        </Col>
      </Row>
      <br /><br />
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card bordered={false} >
            <Title level={4}>Places to Visit</Title>
            <div className="shadow-lg rounded-lg">
              <ul>
                {destination.placesToVisit && destination.placesToVisit.map((place, index) => (
                  <li key={index} style={{ marginBottom: '10px' }}><BiCheck className="mr-2 text-xl text-green-500" /> <span dangerouslySetInnerHTML={{ __html: place }}></span></li>
                ))}
              </ul>
            </div>
          </Card>
        </Col>
      </Row>
      <br /><br />
      {/* <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card bordered={false}>
            <Title level={4}>Travel Tips</Title>
            <div className="shadow-lg rounded-lg">
              <ul>
                {destination.travelTips && destination.travelTips.map((tip, index) => (
                  <li key={index} style={{ marginBottom: '10px' }}><GrDocumentText className="mr-2 text-xl text-blue-500" /> <span dangerouslySetInnerHTML={{ __html: tip }}></span></li>
                ))}
              </ul>
            </div>
          </Card>
        </Col>
      </Row> */}
      <br /><br />
      <div className="container mx-auto py-8">
        <hr />
        <div className="text-center shadow-lg rounded-lg">
          {destination.images && destination.images.length > 0 ? (
            <Carousel autoplay autoplaySpeed={5000} slidesToShow={3} dots>
              {destination.images.map((image, index) => (
                <div key={index} className="h-64 overflow-hidden">
                  <img src={image} className="w-full h-full object-cover" alt={`Image ${index + 1}`} />
                </div>
              ))}
            </Carousel>
          ) : (
            <p className="text-gray-500">No images available</p>
          )}
        </div>
        <hr />
      </div>
      <Divider />
    </div>
  );
};

export default DestinationDetails;