import React, { useState, useEffect } from 'react';
import { Input, Button, Modal, Pagination, Table, Typography } from 'antd';
import { FaSearch, FaTrash, FaPlus } from 'react-icons/fa';
import { fetchTours, deleteTour } from '../../../utils/API/ToursService';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { Link } from 'react-router-dom';

const { confirm } = Modal;
const { Title, Text } = Typography;

const AdminTour = () => {
  const [tours, setTours] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchAllTours();
  }, [currentPage]);

  const fetchAllTours = async () => {
    try {
      const fetchedTours = await fetchTours();
      console.log("fetecded arrays", fetchedTours)
      setTours(fetchedTours.data);
    } catch (error) {
      console.error('Error fetching tours:', error.message);
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const handleDeleteTour = async (id) => {
    try {
      const token = user.token;
      await deleteTour(id, token);
      fetchAllTours();
    } catch (error) {
      console.error('Error deleting tour:', error.message);
    }
  };

  const showDeleteConfirm = (id, title) => {
    confirm({
      title: `Are you sure you want to delete ${title}?`,
      content: 'This action cannot be undone',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteTour(id);
      },
    });
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: '25%',
    },
    {
      title: 'Distance',
      dataIndex: 'distance',
      key: 'distance',
      width: '25%',
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      key: 'actions',
      render: (id, record) => (
        <Button type="danger" onClick={() => showDeleteConfirm(id, record.title)} icon={<FaTrash className='text-red-500' />} />
      ),
    },
  ];

  // const filteredTours = tours.filter(tour =>
  //   tour.title.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  const filteredTours = tours.filter(tour =>
    tour.title && tour.title.toLowerCase().includes(searchQuery.toLowerCase())
);


  const pageSize = 6;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="max-w-screen-lg mx-auto mt-8">
      <div className="flex justify-between items-center mb-4">
        <Link to="/tour/add" className="flex items-center">
          <Button type="primary" icon={<FaPlus />} className="mr-2 bg-blue-500">Add Tour</Button>
        </Link>
      </div>
      <div className="mb-4">
        <Input
          placeholder="Search tours"
          prefix={<FaSearch />}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <Table
        dataSource={filteredTours.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
        columns={columns}
        rowKey="_id"
        pagination={false}
        rowClassName={(record, index) => index % 2 === 0 ? 'bg-blue-100' : 'bg-gray-100'}
        style={{ minWidth: '800px' }}
      />
      <div className="mt-4 flex justify-center">
        <Pagination
          current={currentPage}
          total={filteredTours.length}
          pageSize={pageSize}
          onChange={onPageChange}
        />
      </div>
      <br /><br />
    </div>
  );
};

export default AdminTour;
