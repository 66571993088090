import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Card, Row, Col, Divider, Button, Avatar, List } from 'antd';
import { fetchBlogPostById } from '../../utils/API/blogPostService';
import { AiOutlineLike, AiOutlineShareAlt } from 'react-icons/ai';
import { FaRegCalendarAlt, FaUser } from 'react-icons/fa';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FiTag, FiEye } from 'react-icons/fi';

const { Title, Text } = Typography;

const BlogPostDetails = () => {
  const { id } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchBlogPostById(id);
        setBlogPost(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!blogPost) {
    return <div>Blog post not found</div>;
  }

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="p-8">
      <Title level={2}>{blogPost.title}</Title>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <Card className="bg-white rounded shadow mb-4">
            <Text>{blogPost.content}</Text>
          </Card>
          <Card className="bg-white rounded shadow">
            <Slider {...sliderSettings}>
              {blogPost.images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={`Image ${index + 1}`} style={styles.image} />
                </div>
              ))}
            </Slider>
          </Card>
          <Divider />
          <Card title="Comments" className="bg-white rounded shadow">
            <List
              dataSource={blogPost.comments}
              renderItem={comment => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src={comment.avatar} />}
                    title={<a>{comment.author}</a>}
                    description={comment}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Card title="Author" className="bg-white rounded shadow">
            <div className="flex items-center">
              <FaUser className="mr-2" />
              <Text>{blogPost.author}</Text>
            </div>
          </Card>
          <Card title="Date" className="bg-white rounded shadow mt-4">
            <div className="flex items-center">
              <FaRegCalendarAlt className="mr-2" />
              <Text>{new Date(blogPost.date).toLocaleDateString()}</Text>
            </div>
          </Card>
          <Card title="Tags" className="bg-white rounded shadow mt-4">
            {blogPost.tags.map((tag, index) => (
              <div key={index} className="flex items-center mb-2">
                <FiTag className="mr-2" />
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">{tag}</span>
              </div>
            ))}
          </Card>
          <Card title="Views" className="bg-white rounded shadow mt-4">
            <div className="flex items-center">
              <FiEye className="mr-2" />
              <Text>{blogPost.views}</Text>
            </div>
          </Card>
          <Card title="Likes" className="bg-white rounded shadow mt-4">
            <div className="flex items-center">
              <AiOutlineLike className="mr-2" />
              <Text>{blogPost.likes}</Text>
            </div>
          </Card>
        </Col>
      </Row>
      <Divider />
      <div className="flex">
        <Button className="mr-2 flex items-center">
          <AiOutlineLike className="mr-1" />
          Like
        </Button>
        <Button className="flex items-center">
          <AiOutlineShareAlt className="mr-1" />
          Share
        </Button>
      </div>
    </div>
  );
};

const styles = {
  image: { width: '90%', height: '300px' },
};

export default BlogPostDetails;
