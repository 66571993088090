import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchDestinations } from '../utils/API/destinationService';
import { Pagination } from 'antd';

const Destinations = () => {
  const [destinations, setDestinations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Initial page size, adjust as needed

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDestinations();
        setDestinations(data);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching destinations</div>;

  return (
    <section className="bg-gray-100 py-10">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-semibold mb-8 text-center">Top Destinations</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4">
          {destinations.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((destination, index) => (
            <Link key={index} to={`/destinations/list/${destination._id}`}>
              <div className="relative">
                {destination.coverImage ? (
                  <img
                    className="w-full h-32 object-cover rounded-lg"
                    src={destination.coverImage}
                    alt={destination.name}
                  />
                ) : (
                  <img
                    className="w-full h-auto object-cover aspect-w-18 aspect-h-9 rounded-lg"
                    src="https://okcredit-blog-images-prod.storage.googleapis.com/2021/05/tourandtravel1.jpg"
                    alt="Placeholder"
                  />
                )}
                <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 rounded-lg">
                  <p className="text-white text-lg font-semibold text-center">{destination.name || 'Placeholder Title'}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
        {destinations.length > pageSize && (
          <div className="flex justify-center mt-8">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={destinations.length}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default Destinations;
