import React from "react";
import ReactDOM from "react-dom/client";
import './index.css'
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import { HelmetProvider } from 'react-helmet-async';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   
      <AuthContextProvider>
         <BrowserRouter>
         <HelmetProvider>
      <App />
    </HelmetProvider>
         </BrowserRouter>
      </AuthContextProvider>

);
