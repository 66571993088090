// translationService.js
import axios from 'axios';
import { BASE_URL } from '../config';
const API_BASE_URL =`${BASE_URL}/translation/translate`;

// Translate text
export const translateText = async (text, targetLanguage) => {
  try {
    const response = await axios.post(API_BASE_URL, { text, targetLanguage });
    const { translatedText } = response.data;
    return translatedText;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error('Request failed with status code:', error.response.status);
      console.error('Response data:', error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      console.error('Request failed:', error.message);
    }
    throw new Error('Failed to translate text');
  }
};
