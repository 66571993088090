import React from 'react';
import { Row, Col, Card } from 'antd';
import img1 from '../assets/images/African-Tour-Operation-Association.png';
import img2 from '../assets/images/Ethiopian-Tour-Operation-Association.png';
import img3 from '../assets/images/Get-Your-Guide-1.png';
import img4 from '../assets/images/viator.png';
import img5 from '../assets/images/tripneer.png';
import img6 from '../assets/images/tourradar.png';
import img7 from '../assets/images/safari-booking.png';

const companyUrls = [
  'https://example.com/company1',
  'https://example.com/company2',
  'https://example.com/company3',
  'https://example.com/company4',
  'https://example.com/company5',
  'https://example.com/company6',
  'https://example.com/company7'
];

const Companies = () => {
  const images = [img1, img2, img3, img4, img5, img6, img7];

  return (
    <div style={{ padding: '20px', background: '#f0f2f5' }}>
      <Row gutter={[16, 16]} justify="space-between">
        {images.map((image, index) => (
          <Col key={index}>
            <a href={companyUrls[index]} target="_blank" rel="noopener noreferrer">
              <Card style={{ width: '140px', margin: '0 5px' }}>
                <img alt={`company-${index}`} src={image} style={{ width: '100%', height: '50px', maxHeight: '130px' }} />
              </Card>
            </a>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Companies;
