import React from "react";
import { Row, Col, Typography, Divider, Form, Input, Button, Card } from "antd";
import {
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

const ContactUsPage = () => {
  const handleSubmit = (values) => {
    console.log("Received values:", values);
  };

  return (
    <div style={{ padding: "50px" }}>
      <Title  className="text-center" level={2}>Contact Us</Title>
      <Divider />

      <Row justify="center">
        <Col xs={24} sm={20} md={16} lg={12}>
          <Card
            style={{
              border: "2px solid #333",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          >
            <Form onFinish={handleSubmit} layout="vertical">
              <Form.Item
                label="Your Name"
                name="name"
                rules={[{ required: true, message: "Please enter your name" }]}
              >
                <Input prefix={<UserOutlined />} style={{ borderColor: 'blue' }} />
              </Form.Item>
              <Form.Item
                label="Your Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input prefix={<MailOutlined />} style={{ borderColor: 'blue' }} />
              </Form.Item>
              <Form.Item
                label="Inquiry Type"
                name="inquiryType"
                rules={[
                  { required: true, message: "Please select an inquiry type" },
                ]}
              >
                <Input placeholder="E.g., General Inquiry, Booking, Feedback" style={{ borderColor: 'blue' }} />
              </Form.Item>
              <Form.Item
                label="Your Message"
                name="message"
                rules={[
                  { required: true, message: "Please enter your message" },
                ]}
              >
                <Input.TextArea rows={4} style={{ borderColor: 'blue' }} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block style={{ backgroundColor: '#1890ff' }}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>

      <Row justify="center" style={{ marginTop: "30px" }}>
        <Col xs={24} sm={20} md={16} lg={12}>
          <Card
            style={{
              border: "2px solid #333",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          >
            <Text strong>
              <EnvironmentOutlined style={{ marginRight: "8px" }} />
              Address:
            </Text>
            <Text>123 Tour Street, Addis Ababa, Ethiopia</Text>
            <br />
            <br />
            <Text strong>
              <PhoneOutlined style={{ marginRight: "8px" }} />
              Phone:
            </Text>
            <Text>+251 123 456 789</Text>
            <br />
            <br />
            <Text strong>
              <MailOutlined style={{ marginRight: "8px" }} />
              Email:
            </Text>
            <Text>info@enatethiopiatours.com</Text>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUsPage;
