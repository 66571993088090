import React from 'react';
import { Card, Divider, Row, Col, Carousel, Typography, Tag } from 'antd';
import { FaGlobe, FaCalendarAlt, FaFire, FaMapMarkerAlt, FaRegLightbulb } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const { Meta } = Card;
const { Title, Paragraph } = Typography;

const BahirDar = () => {
  const bahirDarData = {
    "name": "Bahir Dar",
    "description": "Bahir Dar, nestled in northwestern Ethiopia along the serene shores of Lake Tana, embodies a captivating blend of natural splendor, rich history, and vibrant cultural heritage. As the distinguished capital of the Amhara Region, this picturesque city beckons travelers with its breathtaking landscapes, including the majestic Blue Nile Falls and the tranquil monasteries adorning the islands of Lake Tana. Explore the ancient treasures of Ethiopian Orthodox Churches, immerse yourself in the bustling local markets, and savor the flavors of traditional cuisine. With its tropical savanna climate providing year-round warmth, Bahir Dar offers an unforgettable journey into the heart of Ethiopia's captivating charm.",
    "coverImage": "https://t4.ftcdn.net/jpg/03/27/95/93/240_F_327959312_r9b7xPDaQUIDZO0nsuFyy71y8B1mYwvG.jpg",
    "images": [
      "https://as1.ftcdn.net/v2/jpg/03/93/30/36/1000_F_393303631_4wcCfcpkwlZP4Y2l3PwNttLzz6nYbTkV.jpg",
      "https://t4.ftcdn.net/jpg/03/63/31/85/240_F_363318585_PC393BFfaA75n67PibOOrMPF5VDkPywf.jpg",
      "https://t3.ftcdn.net/jpg/03/47/59/58/240_F_347595844_2uUXNqWhl68XuztgEfAul4dGpaMzlsPe.jpg",
      "https://t4.ftcdn.net/jpg/01/77/50/99/240_F_177509991_mcb9bWPO3hHqoWBkQHkDd6oRhT1sGU7s.jpg"
    ],
    "location": "Amhara Region, Ethiopia",
    "climate": "Tropical Savanna Climate",
    "averageTemperature": 25,
    "recommendedMonths": ["October", "November", "December", "January", "February", "March"],
    "popularActivities": [
      "Boat trips on Lake Tana",
      "Hiking to Blue Nile Falls",
      "Visiting monasteries and churches",
      "Exploring local markets"
    ],
    "travelTips": [
      { tip: "Carry light clothing for warm weather", icon: <FaRegLightbulb /> },
      { tip: "Be prepared for occasional rain, especially during the rainy season (June to September)", icon: <FaRegLightbulb /> },
      { tip: "Respect local customs and traditions, especially when visiting religious sites", icon: <FaRegLightbulb /> },
      { tip: "Bargain politely when shopping at local markets", icon: <FaRegLightbulb /> },
      { tip: "Try traditional Ethiopian dishes such as Injera and Doro Wat", icon: <FaRegLightbulb /> },
      { tip: "Learn a few words in Amharic, the official language of Ethiopia, to communicate with locals", icon: <FaRegLightbulb /> }
    ]
  };

  const {
    name,
    description,
    coverImage,
    images,
    location,
    climate,
    averageTemperature,
    recommendedMonths,
    popularActivities,
    travelTips,
  } = bahirDarData;

  return (
    <div className="container mx-auto mt-8">
      <Helmet>
        <title>{name} - Enat Ethiopia Tours</title>
        <meta name="description" content={description} />
        <meta name="keywords" content="Bahir Dar, Ethiopia, Lake Tana, Blue Nile Falls, monasteries, churches, tourism" />
      </Helmet>
      <div className="mb-8">
        <Carousel autoplay>
          {images.map(image => (
            <div key={image}>
              <img src={image} alt={name} className="w-full" style={{ height: '400px', objectFit: 'cover' }} />
            </div>
          ))}
        </Carousel>
        <Title level={2} className="mt-4">{name}</Title>
        <Paragraph style={{ fontSize: '18px', lineHeight: '1.8' }}>{description}</Paragraph>
      </div>

      <Divider orientation="left">Basic Information</Divider>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Card className="bg-gray-100">
            <Meta title="Location" description={<div><FaMapMarkerAlt /> {location}</div>} />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="bg-gray-100">
            <Meta title="Climate" description={<div><FaFire /> {climate}</div>} />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="bg-gray-100">
            <Meta title="Average Temperature" description={<div><FaFire /> {averageTemperature}°C</div>} />
          </Card>
        </Col>
        <Col xs={24} md={12}>
          <Card className="bg-gray-100">
            <Meta title="Recommended Months" description={<div><FaCalendarAlt /> {recommendedMonths.join(', ')}</div>} />
          </Card>
        </Col>
      </Row>

      <Divider orientation="left">Things to Do</Divider>
      <Row gutter={[16, 16]}>
        {popularActivities.map(activity => (
          <Col key={activity} xs={24} md={12} lg={8}>
            <Card className="bg-gray-100">
              <Meta title={activity} />
            </Card>
          </Col>
        ))}
      </Row>

      <Divider orientation="left">Travel Tips</Divider>
      <Row gutter={[16, 16]}>
        {travelTips.map(({ tip, icon }) => (
          <Col key={tip} xs={24} md={12} lg={8}>
            <Card className="bg-gray-100">
              <Meta
                title={tip}
                description={icon}
              />
            </Card>
          </Col>
        ))}
      </Row>
      <br /><br />
    </div>
  );
};

export default BahirDar;
