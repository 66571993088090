import React from "react";
import Header from "./../Header/Header";
import Routers from "../../router/Routers";
import Footer from "./../Footer/Footer";
import Social from './../Header/Social'
import Top from './../Header/Top'

const Layout = () => {
  return (
    <>
    <Top/>
      <Social />
      <Header />

      <Routers />
      <Footer />
    </>
  );
};

export default Layout;
