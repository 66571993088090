import axios from 'axios';
import { BASE_URL } from '../config';

const API_BASE_URL = `${BASE_URL}/tours`;

// Fetch all tours
export const fetchTours = async () => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching tours');
  }
};

// Fetch details of a single tour by ID
export const fetchTourDetails = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching tour details');
  }
};

// Create a new tour
export const createTour = async (newTour) => {
  try {
    const token = localStorage.getItem('accessToken');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(API_BASE_URL, newTour, config);
    return response.data;
  } catch (error) {
    throw new Error('Error creating tour');
  }
};

// Update a tour by ID
export const updateTour = async ({ id, updatedTour }) => {
  try {
    const token = localStorage.getItem('accessToken');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(`${API_BASE_URL}/${id}`, updatedTour, config);
    return response.data;
  } catch (error) {
    throw new Error('Error updating tour');
  }
};

// Delete a tour by ID
export const deleteTour = async (id,token) => {
  try {
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios.delete(`${API_BASE_URL}/${id}`, config);
  } catch (error) {
    throw new Error('Error deleting tour');
  }
};


export const fetchToursByType = async (tourType) => {
  try {
    const response = await axios.get(API_BASE_URL);
    const allTours = response.data.data; // Access the array of tours
    if (!tourType) {
      return allTours;
    } else {
      // Filter tours based on tour type
      const filteredTours = allTours.filter(tour => tour.tourType === tourType);
      return filteredTours;
    }
  } catch (error) {
    // Handle the error
    console.error('Error fetching tours:', error.message);
    throw new Error('Error fetching tours');
  }
};


export default {
  createTour,
  updateTour,
  deleteTour,
  fetchTours,
  fetchTourDetails,
  fetchToursByType 
};
