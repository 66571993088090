import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const TourList = () => {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const response = await fetch('http://localhost:4000/api/v1/tours');
        const data = await response.json();

        if (data.success) {
          setTours(data.data);
        } else {
          console.error('Failed to fetch tours:', data.message);
        }
      } catch (error) {
        console.error('Error fetching tours:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTours();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h2>All Tours</h2>
      {tours.map((tour) => (
        <div key={tour._id}>
          <Link to={`/tour/${tour._id}`}>
            <h3>{tour.title}</h3>
          </Link>
          <p>{tour.desc}</p>
          {/* Add more details as needed */}
        </div>
      ))}
    </div>
  );
};

export default TourList;
