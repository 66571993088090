import React from 'react';
import { Card, Typography } from 'antd';
import './service-card.css';

const { Title, Paragraph } = Typography;

const ServiceCard = ({ item }) => {
  const { imgUrl, title, desc } = item;

  return (
    <Card className='service__card' hoverable>
      <div className='service__img'>
        <img src={imgUrl} alt={title} className="service__icon" />
      </div>
      <div className='service__content'>
        <Title level={5} className="service__title">{title}</Title>
        <Paragraph className="service__desc">{desc}</Paragraph>
      </div>
    </Card>
  );
};

export default ServiceCard;
