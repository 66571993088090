import { useState } from 'react';
import { Form, Input, Button, Checkbox, DatePicker, InputNumber } from 'antd';
import { SendOutlined } from '@material-ui/icons';
import { createTourBooking } from '../../utils/API/bookService'
import { Snackbar } from '@material-ui/core';
const { TextArea } = Input;


const TourBookingForm = () => {
  const [loading, setLoading] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);


  const onFinish = async (values) => {
    setLoading(true); // Set loading state to true while the request is being made
    try {
      // Call the createTourBooking function from the service
      await createTourBooking(values);
      console.log('Tour booking created successfully');
      setSuccessSnackbarOpen(true);
      setTimeout(() => {
        // Redirect user to home page after 3 seconds
        window.location.href = '/';
      }, 3000);
      // Reset the form fields or perform any other necessary actions upon successful booking
    } catch (error) {
      console.error('Error creating tour booking:', error.message);
      // Handle error, display error message to user, etc.
    } finally {
      setLoading(false); // Reset loading state after the request is complete
    }
  };

  return (
    <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-6 mt-5 mb-5">
      <Form
        name="tour_booking"
        onFinish={onFinish}
        layout="vertical"
        initialValues={{ termsAgreed: false }}
      >
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-4">
            <Form.Item
              label="Tour Name"
              name="tourName"
              rules={[{ required: true, message: 'Please input the tour name!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Tour Duration"
              name="tourDuration"
              rules={[{ required: true, message: 'Please input the tour duration!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Tour Start Date"
              name="tourStartDate"
              rules={[{ required: true, message: 'Please select the tour start date!' }]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              label="Tour End Date"
              name="tourEndDate"
              rules={[{ required: true, message: 'Please select the tour end date!' }]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              label="Customer Name"
              name="customerName"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Please enter a valid email address!' },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[{ required: true, message: 'Please input your phone number!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Address"
              name="address"
            >
              <Input />
            </Form.Item>
          </div>
          <div className="w-full md:w-1/2 px-4 mb-4">
            <Form.Item
              label="Total Adults"
              name="totalAdults"
              rules={[{ required: true, message: 'Please input the number of adults!' }]}
            >
              <InputNumber min={1} />
            </Form.Item>

            <Form.Item
              label="Total Children"
              name="totalChildren"
            >
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item
              label="Special Requirements"
              name="specialRequirements"
            >
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item
              label="Special Requests"
              name="specialRequests"
            >
              <TextArea rows={4} />
            </Form.Item>

            {/* <Form.Item
              name="transportationRequired"
              valuePropName="checked"
            >
              <Checkbox>Transportation Required</Checkbox>
            </Form.Item>

            <Form.Item
              name="accommodationRequired"
              valuePropName="checked"
            >
              <Checkbox>Accommodation Required</Checkbox>
            </Form.Item> */}

            <Form.Item
              name="termsAgreed"
              valuePropName="checked"
              rules={[{ required: true, message: 'Please agree to the terms!' }]}
            >
              <Checkbox>
                I agree to the terms and conditions
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
              
                htmlType="submit"
                icon={<SendOutlined />}
                loading={loading}
                className="w-full bg-blue-500"
              >
                Book Tour
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
      <Snackbar
        open={successSnackbarOpen}
        message="Tour booking created successfully!"
        autoHideDuration={3000}
        onClose={() => setSuccessSnackbarOpen(false)}
      />
    </div>
  );
};

export default TourBookingForm;
