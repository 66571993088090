import React, { useEffect, useState } from 'react';
import { Layout, Typography, Card, Pagination, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { fetchBlogPosts, incrementBlogPostLikes, incrementBlogPostViews, addCommentToBlogPost } from '../../utils/API/blogPostService';
import { FaRegThumbsUp, FaRegEye, FaRegComment } from 'react-icons/fa';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchBlogPosts();
        setBlogPosts(data);
        console.log("blogData",data)
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  // Logic for pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Increment likes
  const handleLike = async (postId) => {
    try {
      await incrementBlogPostLikes(postId);
      message.success('Liked the blog post!');
      // Update local state to reflect the new likes count
      const updatedPosts = blogPosts.map(post => {
        if (post._id === postId) {
          return { ...post, likes: post.likes + 1 };
        }
        return post;
      });
      setBlogPosts(updatedPosts);
    } catch (error) {
      console.error('Error liking blog post:', error);
      message.error('Failed to like the blog post.');
    }
  };

  // Track view
  const trackView = async (postId) => {
    try {
      await incrementBlogPostViews(postId);
      // Refresh blog posts after view
      const updatedPosts = await fetchBlogPosts();
      setBlogPosts(updatedPosts);
    } catch (error) {
      console.error('Error incrementing views:', error);
    }
  };

  // Add comment
  const handleAddComment = async (postId, comment) => {
    try {
      await addCommentToBlogPost(postId, { text: comment });
      message.success('Comment added successfully!');
      // Refresh blog posts after comment
      const updatedPosts = await fetchBlogPosts();
      setBlogPosts(updatedPosts);
    } catch (error) {
      console.error('Error adding comment:', error);
      message.error('Failed to add comment.');
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content className="p-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="md:col-span-2">
            <Title level={2} className="mb-4">Blog Posts</Title>
            <div className="grid gap-4">
              {currentPosts.map((post) => (
                <Card
                  key={post._id}
                  title={post.title}
                  extra={<Link to={`/blog/${post._id}`} className="text-blue-500">Read More</Link>}
                  className="mb-8"
                  cover={<img alt={post.title} src={post.images[0]} className="h-72 object-cover" />}
                  onClick={() => trackView(post._id)} // Track view on card click
                >
                  <Paragraph ellipsis={{ rows: 2, expandable: true }}>{post.content}</Paragraph>
                  <div className="flex justify-between mt-4">
                    <Button icon={<FaRegThumbsUp />} onClick={() => handleLike(post._id)}>Like ({post.likes})</Button>
                    <Button icon={<FaRegEye />} onClick={() => trackView(post._id)}>View ({post.views})</Button>
                    {post.comments && (
                      <Button icon={<FaRegComment />}>
                        <Link to={`/blog/${post._id}#comments`} className="text-blue-500">Comments ({post.comments.length})</Link>
                      </Button>
                    )}
                  </div>
                </Card>
              ))}
            </div>
            <Pagination
              current={currentPage}
              total={blogPosts.length}
              pageSize={postsPerPage}
              onChange={handlePageChange}
              className="mt-4"
            />
          </div>
          <div className="hidden md:block">
            {/* Display latest blogs on the right side */}
            <Title level={4} className="mb-4">Latest Blogs</Title>
            <div>
              {blogPosts.slice(0, 6).map((post) => (
                <Card
                  key={post._id}
                  title={post.title}
                  extra={<Link to={`/blog/${post._id}`} className="text-blue-500">Read More</Link>}
                  style={{ maxWidth: '300px', margin: '0 auto' }}
                  cover={<img alt={post.title} src={post.images[0]} style={{ width: '100%', height: '200px', objectFit: 'cover' }} />}
                >
                  <Paragraph ellipsis={{ rows: 2, expandable: true }}>{post.description}</Paragraph>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default BlogList;
