import axios from 'axios';
import { BASE_URL } from '../config';

const API_BASE_URL = `${BASE_URL}/blog-posts`;

// Fetch all blog posts
export const fetchBlogPosts = async () => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching blog posts');
  }
};

// Fetch a single blog post by ID
export const fetchBlogPostById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching blog post by ID');
  }
};

// Create a new blog post
export const createBlogPost = async (newBlogPost, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(API_BASE_URL, newBlogPost, config);
    return response.data;
  } catch (error) {
    throw new Error('Error creating blog post');
  }
};

// Update a blog post by ID
export const updateBlogPost = async ({ id, updatedBlogPost, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(`${API_BASE_URL}/${id}`, updatedBlogPost, config);
    return response.data;
  } catch (error) {
    throw new Error('Error updating blog post');
  }
};

// Delete a blog post by ID
export const deleteBlogPost = async (id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios.delete(`${API_BASE_URL}/${id}`, config);
  } catch (error) {
    throw new Error('Error deleting blog post');
  }
};

// Increment likes for a blog post
export const incrementBlogPostLikes = async (id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(`${API_BASE_URL}/${id}/like`, null, config);
    return response.data;
  } catch (error) {
    throw new Error('Error incrementing likes for blog post');
  }
};

// Increment views for a blog post
export const incrementBlogPostViews = async (id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(`${API_BASE_URL}/${id}/view`, null, config);
    return response.data;
  } catch (error) {
    throw new Error('Error incrementing views for blog post');
  }
};

// Add a comment to a blog post
export const addCommentToBlogPost = async (id, comment, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(`${API_BASE_URL}/${id}/comment`, comment, config);
    return response.data;
  } catch (error) {
    throw new Error('Error adding comment to blog post');
  }
};

export default {
  fetchBlogPosts,
  fetchBlogPostById,
  createBlogPost,
  updateBlogPost,
  deleteBlogPost,
  incrementBlogPostLikes,
  incrementBlogPostViews,
  addCommentToBlogPost,
};
