import axios from 'axios';
import { BASE_URL } from '../../utils/config';

const TOURS_API = `${BASE_URL}/tours`;

export const createTour = async (tourData, token) => {
  try {
    const response = await axios.post(TOURS_API, tourData, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating tour:', error);
    throw error;
  }
};

export const updateTourById = async (tourId, updatedTourData, token) => {
  try {
    const response = await axios.put(`${TOURS_API}/${tourId}`, updatedTourData, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating tour with ID ${tourId}:`, error);
    throw error;
  }
};

export const deleteTourById = async (tourId, token) => {
  try {
    const response = await axios.delete(`${TOURS_API}/${tourId}`, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the Authorization header
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error deleting tour with ID ${tourId}:`, error);
    throw error;
  }
};
