import React from 'react';
import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  VideoCameraOutlined,
  FileTextOutlined,
  BookOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Sider } = Layout;

const AdminDashboard = ({ name }) => {
  return (
    <Sider width={250} className="admin-sider">
     
      <div className="admin-menu">
        <Menu
          mode="horizontal"
          defaultSelectedKeys={['1']}
          style={{ border: 'none', lineHeight: '64px' }}
        >
            <Menu.Item key="2" icon={<VideoCameraOutlined />}>
            <Link to="/tours/admin">Manage Tours</Link>
          </Menu.Item>
          <Menu.Item key="1" icon={<UserOutlined />}>
            <Link to="/destinations/admin">Manage Destinations</Link>
          </Menu.Item>
          
          <Menu.Item key="3" icon={<FileTextOutlined />}>
            <Link to="/blogs/admin">Manage Blogs</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<BookOutlined />}>
            <Link to="/books/admin">Manage Bookings</Link>
          </Menu.Item>
        </Menu>
      </div>
     
    </Sider>
  );
};

export default AdminDashboard;
