import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Terrain, WbSunny, LocalHospital, LocalHospitalOutlined, FlightTakeoff, Event, AttachMoney, Language, Wifi
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  card: {
    marginBottom: theme.spacing(3),
  },
  icon: {
    color: theme.palette.primary.main,
  },
  header: {
    marginBottom: theme.spacing(4),
    fontWeight: 'bold',
  },
  listItem: {
    paddingLeft: 0,
  },
  listItemIcon: {
    minWidth: theme.spacing(4),
  },
}));

const TravelTips = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography variant="h3" align="center" className={classes.header}>
        Travel Tips
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Terrain className={classes.icon} fontSize="large" />
                <Typography variant="h5" component="h2" style={{ marginLeft: 8 }}>
                  Topography & Climate
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                Ethiopia has an elevated plateau ranging in height between 2,000 to 3,000 meters. In the north and southeast, there are high mountains with peaks rising above 4,300 meters. The country also has two major favorable seasons categorized as wet and dry seasons.
              </Typography>
              <Typography variant="body1" paragraph>
                The rainy season normally starts mid-June and continues until early October, but this pattern may change across different regions. Check the average temperatures when planning your visit due to geographical variation.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <LocalHospital className={classes.icon} fontSize="large" />
                <Typography variant="h5" component="h2" style={{ marginLeft: 8 }}>
                  Health Requirements
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                A yellow fever vaccination certificate is not required unless coming from an endemic area, but it is recommended. It is also advisable to be immunized for Hepatitis A and B, Typhoid, and Polio. Malaria is a risk in lowland areas, so bring mosquito repellent and consider anti-malarial tablets.
              </Typography>
              <Typography variant="body1" paragraph>
                Health insurance is highly recommended. A first aid pack should include antihistamines, antiseptic cream, plasters, high SPF sunscreen, anti-diarrhea medication, and oral rehydration salts.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <FlightTakeoff className={classes.icon} fontSize="large" />
                <Typography variant="h5" component="h2" style={{ marginLeft: 8 }}>
                  Visa Requirements
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                Tourist visas for one or three months (single entry) are available online. Visit www.evisa.gov.et for applications. Requirements include arrival information, personal details, a passport-size photo, a copy of your passport, and a non-refundable payment fee.
              </Typography>
              <Typography variant="body1" paragraph>
                Business visas are available upon arrival with prior arrangements. Extensions can be obtained at the Immigration Office in Addis Ababa, with fees and penalties applicable for overstays.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Event className={classes.icon} fontSize="large" />
                <Typography variant="h5" component="h2" style={{ marginLeft: 8 }}>
                  Best Time to Travel
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                The best time to travel to Ethiopia is during the dry season (mid-October to May). Depending on your budget, visiting during the low season (June to September) offers many activities and festivals. Specific recommendations include:
              </Typography>
              <List>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Event className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="North: October to March" />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Event className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="South: August to October" />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Event className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary="Danakil Depression: November to January" />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <AttachMoney className={classes.icon} fontSize="large" />
                <Typography variant="h5" component="h2" style={{ marginLeft: 8 }}>
                  Money Exchange
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                The Ethiopian Birr (ETB) is available in denominations of 5, 10, 50, 100, and 200 notes. Exchange US dollars or euros at the airport or banks. Banks require receipts for foreign currency exchange. Credit cards are accepted in hotels, and ATMs are available in main cities.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Language className={classes.icon} fontSize="large" />
                <Typography variant="h5" component="h2" style={{ marginLeft: 8 }}>
                  Telephone and Internet Access
                </Typography>
              </Box>
              <Typography variant="body1" paragraph>
                EthioTelecom is the sole telecom operator. Buy a SIM card with a passport photocopy and a 30 ETB fee. Wi-Fi is available in most hotels in Addis Ababa but limited in smaller cities. The country dialing code is +251.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TravelTips;
