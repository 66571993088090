import React, { useEffect, useState } from 'react';
import { Layout, Card, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { fetchDestinations } from '../../utils/API/destinationService';

const { Content } = Layout;

const DestinationList = () => {
  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const destinationsPerPage = 6; // Displaying three cards per row

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDestinations();
        setDestinations(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Loading...</div>;
  }

  // Logic for pagination
  const indexOfLastDestination = currentPage * destinationsPerPage;
  const indexOfFirstDestination = indexOfLastDestination - destinationsPerPage;
  const currentDestinations = destinations.slice(indexOfFirstDestination, indexOfLastDestination);

  // Change page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content className="p-4">
        <h2 className="text-3xl font-bold mb-8">Top 10 Destinations</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {currentDestinations.map((destination) => (
            <Card
              key={destination._id}
              cover={<img alt={destination.name} src={destination.coverImage} className="h-40 md:h-56 object-cover" />}
              className="shadow-md hover:shadow-lg transition duration-300"
              size="small"
            >
              <div className="p-3">
                <h3 className="text-lg font-semibold mb-2">{destination.name}</h3>
                {destination.description.length > 400 ? (
  <p className="text-gray-600">{`${destination.description.slice(0, 400)}...`}</p>
) : (
  <p className="text-gray-600">{destination.description}</p>
)}

              </div>
              <div className="mt-4 flex justify-end">
                <Link to={`/destinations/list/${destination._id}`} className="text-blue-500 font-medium">
                  View Details
                </Link>
              </div>
            </Card>
          ))}
        </div>
        <div className="mt-8 flex justify-center">
          <Pagination
            current={currentPage}
            total={destinations.length}
            pageSize={destinationsPerPage}
            onChange={handlePageChange}
          />
        </div>
      </Content>
    </Layout>
  );
};

export default DestinationList;
