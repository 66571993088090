import React, { useState, useEffect } from 'react';
import { Button, Modal, Pagination, Table, Typography, Input, Tag } from 'antd';
import { FaSearch, FaTrash, FaInfoCircle, FaCalendarAlt, FaUserAlt, FaEnvelope, FaPhone, FaMapMarker, FaCheckCircle } from 'react-icons/fa';
import { getAllTourBookings, deleteTourBookingById, getTourBookingById } from '../../../utils/API/bookService';
import moment from 'moment';

const { confirm } = Modal;
const { Title, Text } = Typography;

const AdminBooking = () => {
  const [bookings, setBookings] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBooking, setSelectedBooking] = useState(null); // To store details of the selected booking

  useEffect(() => {
    fetchAllBookings();
  }, [currentPage]);

  const fetchAllBookings = async () => {
    try {
      const response = await getAllTourBookings();
      if (response.success) {
        const fetchedBookings = response.data;
        setBookings(fetchedBookings);
      } else {
        console.error('Error fetching bookings:', response.message);
      }
    } catch (error) {
      console.error('Error fetching bookings:', error.message);
    }
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const handleDeleteBooking = async (id) => {
    try {
      await deleteTourBookingById(id);
      fetchAllBookings();
    } catch (error) {
      console.error('Error deleting booking:', error.message);
    }
  };

  const showDeleteConfirm = (id, title) => {
    confirm({
      title: `Are you sure you want to delete booking with ID ${id}?`,
      content: 'This action cannot be undone',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteBooking(id);
      },
    });
  };

  const showBookingDetails = async (id) => {
    try {
      const booking = await getTourBookingById(id);
      setSelectedBooking(booking);
    } catch (error) {
      console.error('Error fetching booking details:', error.message);
    }
  };

  const columns = [
    {
      title: 'Booking ID',
      dataIndex: '_id',
      key: '_id',
      width: '15%',
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customerName',
      width: '15%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '15%',
      render: (email) => <a href={`mailto:${email}`}><FaEnvelope /> {email}</a>,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '15%',
      render: (phoneNumber) => <a href={`tel:${phoneNumber}`}><FaPhone /> {phoneNumber}</a>,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: '20%',
      render: (address) => <><FaMapMarker /> {address}</>,
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      key: 'actions',
      render: (id, record) => (
        <span>
          <Button type="primary" onClick={() => showBookingDetails(id)} icon={<FaInfoCircle />} className="mr-2 bg-blue-500" />
          <Button type="danger" onClick={() => showDeleteConfirm(record._id, record.customerName)} icon={<FaTrash />} className="bg-red-500" />
        </span>
      ),
      width: '20%',
    },
  ];

  const pageSize = 6;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="max-w-screen-lg mx-auto mt-8">
      <div className="flex justify-between items-center mb-4">
        <Input
          placeholder="Search bookings"
          prefix={<FaSearch />}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <Table
        dataSource={bookings}
        columns={columns}
        rowKey="_id"
        pagination={false}
        rowClassName={(record, index) => index % 4 === 0 ? 'bg-blue-100' : index % 4 === 1 ? 'bg-blue-200' : index % 4 === 2 ? 'bg-blue-300' : 'bg-blue-400'}
        style={{ minWidth: '800px' }}
      />
      <div className="mt-4 flex justify-center">
        <Pagination
          current={currentPage}
          total={bookings.length}
          pageSize={pageSize}
          onChange={onPageChange}
        />
      </div>
      {/* Modal to display booking details */}
      <Modal
        title="Booking Details"
        visible={!!selectedBooking}
        onCancel={() => setSelectedBooking(null)}
        footer={null}
      >
        {selectedBooking && (
          <div>
            <div style={{ marginBottom: '10px' }}>
              <Title level={4}>Booking ID: {selectedBooking._id}</Title>
              <Text strong><FaCalendarAlt /> Tour Start Date:</Text>
              <Text>{moment(selectedBooking.tourStartDate).format('MMMM Do, YYYY')}</Text>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Text strong><FaCalendarAlt /> Tour End Date:</Text>
              <Text>{moment(selectedBooking.tourEndDate).format('MMMM Do, YYYY')}</Text>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Text strong><FaUserAlt /> Customer Name:</Text>
              <Text>{selectedBooking.customerName}</Text>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Text strong><FaEnvelope /> Email:</Text>
              <Text>{selectedBooking.email}</Text>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Text strong><FaPhone /> Phone Number:</Text>
              <Text>{selectedBooking.phoneNumber}</Text>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Text strong><FaMapMarker /> Address:</Text>
              <Text>{selectedBooking.address}</Text>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Text strong>Accommodation Required:</Text>
              <Text>{selectedBooking.accommodationRequired ? <FaCheckCircle /> : 'No'}</Text>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Text strong>Transportation Required:</Text>
              <Text>{selectedBooking.transportationRequired ? <FaCheckCircle /> : 'No'}</Text>
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Text strong>Terms Agreed:</Text>
              <Text>{selectedBooking.termsAgreed ? <FaCheckCircle /> : 'No'}</Text>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AdminBooking;
