import React from "react";
import { PhoneOutlined, MailOutlined, WhatsAppOutlined } from "@ant-design/icons";

const TopPage = () => {
  return (
    <div className="top-page bg-gray-700 p-2">
      <div className="flex flex-col md:flex-row items-center justify-center md:justify-between space-y-2 md:space-y-0 md:space-x-32">
        <div className="flex items-center">
          <PhoneOutlined style={{ fontSize: "1.75rem", color: "#FFA500", marginRight: "0.5rem" }} />
          <a href="tel:+251923654512" style={{ fontSize: "1.25rem", color: "#FFA500", textDecoration: "none" }} className="hover:text-orange-700 ml-2 md:ml-0">+251 923 654 512</a>
        </div>
        <div className="flex items-center  md:mt-0">
          <MailOutlined style={{ fontSize: "1.75rem", color: "#FFA500", marginRight: "0.5rem" }} />
          <a href="mailto:ethiopiantraveltours@gmail.com" style={{ fontSize: "1.25rem", color: "#FFA500", textDecoration: "none" }} className="hover:text-orange-700 ml-2 md:ml-0">ethiopiantraveltours@gmail.com</a>
        </div>
        <div className="flex items-center mt-2 md:mt-0">
          <WhatsAppOutlined style={{ fontSize: "1.75rem", color: "#FFA500", marginRight: "0.5rem" }} />
          <a href="https://wa.me/251923654512" style={{ fontSize: "1.25rem", color: "#FFA500", textDecoration: "none" }} className="hover:text-orange-700 ml-2 md:ml-0">+251 923 654 512</a>
        </div>
      </div>
    </div>
  );
};

export default TopPage;
