import axios from 'axios';
import { BASE_URL } from '../config';


// Create new tour booking
export const createTourBooking = async (tourBookingData) => {
  try {
    const response = await axios.post(`${BASE_URL }/tour-bookings`, tourBookingData);
    return response.data;
  } catch (error) {
    throw new Error('Error creating tour booking');
  }
};

// Get single tour booking by ID
export const getTourBookingById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/tour-bookings/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching tour booking by ID');
  }
};

// Get all tour bookings
export const getAllTourBookings = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/tour-bookings`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching all tour bookings');
  }
};

// Update tour booking by ID
export const updateTourBookingById = async (id, updatedTourBookingData) => {
  try {
    const response = await axios.put(`${BASE_URL}/tour-bookings/${id}`, updatedTourBookingData);
    return response.data;
  } catch (error) {
    throw new Error('Error updating tour booking');
  }
};

// Delete tour booking by ID
export const deleteTourBookingById = async (id) => {
  try {
    await axios.delete(`${BASE_URL}/tour-bookings/${id}`);
  } catch (error) {
    throw new Error('Error deleting tour booking');
  }
};
