import React, { useContext, useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Drawer, Button, Dropdown, Menu } from "antd";
import {
  MenuOutlined,
  CloseOutlined,
  UserOutlined,
  DownOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { AuthContext } from "../../context/AuthContext";

const navLinks = [
  {
    path: "/",
    display: "Home",
  },
  {
    path: "/tours",
    display: "Tours",
    dropdown: [],
  },
  {
    path: "/special-interest-tours",
    display: "Special Interest Tours",
    dropdown: [
      "Activity",
      "Trekking and Hiking",
      "Adventure Tours",
      "Coffee Tours",
      "Birding Tours",
      "Safari",
    ],
  },
  {
    path: "/destinations",
    display: "Destinations",
  },
  {
    path: "/book-now",
    display: "Book Now",
  },
  {
    path: "/about",
    display: "About",
  },
  {
    path: "/discover",
    display: "Discover",
    dropdown: ["Ethiopia", "Blog", "Photo Gallery", "Travel Tips"],
  },
];

const Header = () => {
  const { user, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const isMobileDevice = () => {
      return (
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1
      );
    };
    setIsMobile(isMobileDevice());
  }, []);

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  const toggleDrawer = () => {
    setIsDrawerVisible((prevState) => !prevState);
  };

  const handleMenuClick = (e) => {
    navigate(e.key);
  };

  const handleNavLinkClick = (path) => {
    if (isMobile) {
      navigate(path);
      toggleDrawer();
    }
  };

  const hoverProps = isMobile ? { onClick: handleNavLinkClick } : {};

  return (
    <header className="shadow-md py-4 bg-gray-700">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div className="flex items-center space-x-8">
          <div className="md:hidden">
            <Button type="text" onClick={toggleDrawer}>
              <MenuOutlined style={{ fontSize: "1.5rem", color: "#fff" }} />
            </Button>
          </div>
          {navLinks.map((item, index) => (
            <div
              key={index}
              className="relative hidden md:block transition-all duration-300"
            >
              {item.dropdown && item.dropdown.length > 0 ? (
                <Dropdown
                  overlay={
                    <Menu>
                      {item.dropdown.map((tour, index) => (
                        <Menu.Item key={index}>
                          <NavLink
                            to={`/${tour.toLowerCase().replace(/\s+/g, "-")}`}
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                              fontWeight: "bold",
                            }}
                          >
                            <span>{tour}</span>
                          </NavLink>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  trigger={["hover"]} // Changed trigger to "hover"
                  placement="bottomCenter"
                >
                  <NavLink
                    to={item.path}
                    activeClassName="text-orange-500"
                    className="hover:text-orange-500 transition-all duration-300 text-lg text-white font-semibold mb-1 relative"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      fontFamily: "Arial, sans-serif",
                      padding: "8px",
                    }}
                    {...hoverProps}
                  >
                    <div
                      className={`absolute top-0 left-0 w-full h-1 bg-yellow-500 transition-all duration-300 ${
                        window.location.pathname === item.path ? "opacity-100" : "opacity-0"
                      }`}
                    ></div>
                    {item.display} {item.dropdown && <CaretDownOutlined />}
                  </NavLink>
                </Dropdown>
              ) : (
                <NavLink
                  to={item.path}
                  activeClassName="text-orange-500"
                  className="hover:text-orange-500 transition-all duration-300 text-lg text-white mb-1 relative"
                  style={{
                    textDecoration: "none",
                    padding: "8px",
                    fontFamily: "Arial, sans-serif",
                  }}
                  {...hoverProps}
                >
                  <div
                    className={`absolute top-0 left-0 w-full h-1 bg-yellow-500 transition-all duration-300 ${
                      window.location.pathname === item.path ? "opacity-100" : "opacity-0"
                    }`}
                  ></div>
                  {item.display}
                </NavLink>
              )}
            </div>
          ))}
        </div>
        <div>
          {user && (
            <div className="flex items-center space-x-4 text-white">
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item onClick={logout}>Logout</Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button type="text" style={{ color: "#fff" }}>
                  <UserOutlined />
                  <DownOutlined />
                </Button>
              </Dropdown>
              <span>{user.data.username}</span>
            </div>
          )}
          {!user && (
            <Button
              type="text"
              className="text-white"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          )}
        </div>
      </div>

      <Drawer
        placement="right"
        closable={false}
        onClose={toggleDrawer}
        visible={isDrawerVisible}
      >
        <div className="flex justify-between items-center px-4 py-2">
          <Button type="text" onClick={toggleDrawer}>
            <CloseOutlined style={{ fontSize: "1.5rem" }} />
          </Button>
        </div>
        <div className="flex flex-col items-start space-y-4 ml-4">
          {navLinks.map((item, index) => (
            <div key={index} className="relative">
              {item.dropdown && item.dropdown.length > 0 ? (
                <Dropdown
                  overlay={
                    <Menu>
                      {item.dropdown.map((tour, index) => (
                        <Menu.Item key={index}>
                          <NavLink
                            to={`/${tour.toLowerCase().replace(/\s+/g, "-")}`}
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                              fontWeight: "bold",
                            }}
                          >
                            <span>{tour}</span>
                          </NavLink>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  trigger={["hover"]} // Changed trigger to "hover"
                  placement="bottomCenter"
                >
                  <NavLink
                    to={item.path}
                    activeClassName="text-orange-500"
                    className="hover:text-orange-500 transition-all duration-300 text-lg text-black font-bold mb-1 relative"
                    style={{ textDecoration: "none", fontWeight: "bold" }}
                    {...hoverProps}
                  >
                    <div
                      className={`absolute top-0 left-0 w-full h-1 bg-yellow-500 transition-all duration-300 ${
                        window.location.pathname === item.path ? "opacity-100" : "opacity-0"
                      }`}
                    ></div>
                    {item.display} {item.dropdown && <DownOutlined />}
                  </NavLink>
                </Dropdown>
              ) : (
                <NavLink
                  to={item.path}
                  activeClassName="text-orange-500"
                  className="hover:text-orange-500 transition-all duration-300 text-lg text-black mb-1 relative"
                  style={{ textDecoration: "none", padding: "8px" }}
                  {...hoverProps}
                >
                  <div
                    className={`absolute top-0 left-0 w-full h-1 bg-yellow-500 transition-all duration-300 ${
                      window.location.pathname === item.path ? "opacity-100" : "opacity-0"
                    }`}
                  ></div>
                  {item.display}
                </NavLink>
              )}
            </div>
          ))}
        </div>
      </Drawer>
    </header>
  );
};

export default Header;
