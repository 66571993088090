// destinationService.js
import axios from 'axios';
import { BASE_URL } from '../config';
const API_BASE_URL = `${BASE_URL}/destinations`;


// Fetch all destinations
export const fetchDestinations = async () => {
  try {
    const response = await axios.get(API_BASE_URL);
    console.log("ids",response.data)
    return response.data;
  } catch (error) {
    throw new Error('Error fetching destinations');
  }
};

// Fetch a single destination by ID
export const fetchDestinationById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}`);
    console.log(response)
    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error('Error fetching destination by ID');
  }
};

// Create a new destination
export const createDestination = async (newDestination,token) => {
  try {
   
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(API_BASE_URL, newDestination, config);
    return response.data;
  } catch (error) {
    throw new Error('Error creating destination');
  }
};

// Update a destination by ID
export const updateDestination = async (id, updatedDestination) => {
  try {
    const token = localStorage.getItem('accessToken');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(`${API_BASE_URL}/${id}`, updatedDestination, config);
    return response.data;
  } catch (error) {
    throw new Error('Error updating destination');
  }
};

// Delete a destination by ID
// Delete a destination by ID
export const deleteDestination = async (id, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios.delete(`${API_BASE_URL}/${id}`, config);
  } catch (error) {
    throw new Error('Error deleting destination');
  }
};

