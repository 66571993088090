import React, { useState, useRef, useEffect, useContext } from "react";
import "../styles/tour-details.css";
// import tourData from '../assets/data/tours'
import {
  Container,
  Row,
  Col,
  Form,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import { useParams } from "react-router-dom";
import calculateAvgRating from "../utils/avgRating";
import avatar from "../assets/images/avatar.jpg";
import Booking from "../components/Booking/Booking";
import Newsletter from "../shared/Newsletter";
import useFetch from "../hooks/useFetch";
import { BASE_URL } from "../utils/config";
import { AuthContext } from "../context/AuthContext";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

import TourSEO from "./TourSEO";
import { translateText } from "../utils/API/translationService";

import { Spin } from "antd";
import ReactCountryFlag from "react-country-flag";
import { Link, useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { FaFacebook, FaTwitter, FaWhatsapp } from "react-icons/fa";

import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Collapse } from "antd";
const { Panel } = Collapse;

const TourDetails = () => {
  const { id } = useParams();
  const reviewMsgRef = useRef("");
  const [tourRating, setTourRating] = useState(null);
  const { user } = useContext(AuthContext);
  const [showMore, setShowMore] = useState(false); // Define showMore state
  const location = useLocation(); // Get the current location (URL)
  const currentURL = `${window.location.origin}${location.pathname}`; // Construct the full curre

  const [selectedLanguage, setSelectedLanguage] = useState("");

  const [translatedDesc, setTranslatedDesc] = useState("");
  const [loadingTranslation, setLoadingTranslation] = useState(false);
  const [translationInitiated, setTranslationInitiated] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);

  // Function to handle accordion toggle
  const handleAccordionToggle = (key) => {
    setActiveAccordion(activeAccordion === key ? null : key);
  };

  // fetch data from database
  const { data: tour, loading, error } = useFetch(`${BASE_URL}/tours/${id}`);
  console.log("tour", tour);

  const {
    photo,
    title,
    desc,
    price,
    reviews,
    city,
    address,
    distance,
    maxGroupSize,
    included,
    excluded,
    itinerary,
    photos,
    highlights,
  } = tour;
  const { metaTitle, metaDescription, keywords } = tour;
  // console.log( metaTitle, metaDescription, keywords )
  const { totalRating, avgRating } = calculateAvgRating(reviews);

  const options = { day: "numeric", month: "long", year: "numeric" };
  const navigate = useNavigate(); // Initialize navigate
  // Function to handle sharing the current route
  const shareCurrentRoute = (socialMedia) => {
    // Constructing the share URL based on the selected social media and current route
    let shareURL = "";
    switch (socialMedia) {
      case "facebook":
        shareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          currentURL
        )}`;
        break;
      case "twitter":
        shareURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          currentURL
        )}`;
        break;
      case "whatsapp":
        shareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          currentURL
        )}`;
        break;
      default:
        break;
    }
    // Opening the share URL in a new tab
    window.open(shareURL, "_blank");
  };

  // Check if the user is an admin
  const isAdmin = user && user.role === "admin";

  // Navigate to the edit-tours page with tour details as state
  const handleEditTour = () => {
    navigate("/edit-tours", { state: { tour } });
    console.log("to be edited tour", tour);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const reviewText = reviewMsgRef.current.value;

    try {
      if (!user || user === undefined || user === null) {
        alert("Please sign in");
      }
      const reviewObj = {
        username: user?.username,
        reviewText,
        rating: tourRating,
      };

      const res = await fetch(`${BASE_URL}/review/${id}`, {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(reviewObj),
      });

      const result = await res.json();
      if (!res.ok) {
        return alert(result.message);
      }
      alert(result.message);
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tour]);
  useEffect(() => {
    // Update selected photo to the first image from the photos array
    setSelectedPhoto(photos && photos.length > 0 ? photos[0] : null);
  }, [photos]);

  useEffect(() => {
    if (desc && selectedLanguage && !translationInitiated) {
      // Only translate if translation has not been initiated before and selectedLanguage is not empty
      handleLanguageChange(selectedLanguage);
      setTranslationInitiated(true);
    }
  }, [desc, selectedLanguage, translationInitiated]);

  const handleLanguageChange = async (language) => {
    setLoadingTranslation(true); // Set loading state to true before translation

    setSelectedLanguage(language);
    try {
      const translatedDescription = await translateText(desc, language);
      setTranslatedDesc(translatedDescription);
      setLoadingTranslation(false); // Set loading state to false after translation
    } catch (error) {
      console.error("Error translating description:", error);
      // Handle error here, such as setting a default translated description or showing an error message to the user
      setTranslatedDesc(desc);
    }
  };

  const [selectedPhoto, setSelectedPhoto] = useState(
    photos && photos.length > 0 ? photos[0] : null
  );
  console.log("console loged photo", photos && photos[0]);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };

  const visiblePhotos = showMore ? photos || [] : (photos || []).slice(0, 6); // Add null check before accessing slice
  return (
    <section>
      <TourSEO
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        keywords={keywords}
      />

      <Container>
        {/* {loading && <h4 className='text-center pt-5'>LOADING.........</h4>} */}
        {(loading || loadingTranslation) && (
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-black bg-opacity-50 rounded-lg p-8 shadow-lg">
            <Spin size="large" />
            <p className="text-white mt-4">
              {loadingTranslation
                ? `Translating to ${selectedLanguage.toUpperCase()}...`
                : "Loading..."}
            </p>
          </div>
        )}
        {error && <h4 className="text-center pt-5">{error}</h4>}
        {!loading && !error && (
          <Row>
            <Col lg="8">
              <div className="tour__content">
                <div className="language__selection flex space-x-4 mt-4 mb-4 justify-end">
                  <button
                    onClick={() => handleLanguageChange("en")}
                    className="language-button"
                  >
                    <ReactCountryFlag
                      countryCode="US"
                      svg
                      style={{ fontSize: "1.5em" }}
                    />
                  </button>
                  <button
                    onClick={() => handleLanguageChange("es")}
                    className="language-button"
                  >
                    <ReactCountryFlag
                      countryCode="ES"
                      svg
                      style={{ fontSize: "1.5em" }}
                    />
                  </button>
                  <button
                    onClick={() => handleLanguageChange("fr")}
                    className="language-button"
                  >
                    <ReactCountryFlag
                      countryCode="FR"
                      svg
                      style={{ fontSize: "1.5em" }}
                    />
                  </button>
                  <button
                    onClick={() => handleLanguageChange("de")}
                    className="language-button"
                  >
                    <ReactCountryFlag
                      countryCode="DE"
                      svg
                      style={{ fontSize: "1.5em" }}
                    />
                  </button>
                </div>

                <img src={photo} alt="" />

                <div className="tour__info">
                  <h2>{title}</h2>
                  <div className="d-flex align-items-center gap-5">
                    <span className="tour__rating d-flex align-items-center gap-1">
                      <i
                        class="ri-star-fill"
                        style={{ color: "var(--secondary-color)" }}
                      ></i>
                      {avgRating === 0 ? "Not rated" : <span>5</span>}
                    </span>

                    <span>
                      <i class="ri-map-pin-fill"></i> {address}
                    </span>
                  </div>

                  <div className="tour__extra-details">
                    <span>
                      <i class="ri-map-pin-2-line"></i> {city}
                    </span>
                    <span>
                      <i class="ri-money-dollar-circle-line"></i> {price}/ per
                      person
                    </span>
                    <span>
                      <i class="ri-map-pin-time-line"></i> {distance} k/m
                    </span>
                    <span>
                      <i class="ri-group-line"></i> {maxGroupSize} people
                    </span>
                  </div>
                  <h5>Description</h5>
                  <p>{translatedDesc || desc}</p>
                </div>
                <Container className="mt-5">
  <Row className="tour__info">
    <Col>
      <h4 className="text-bold mb-4">Tour Itinerary</h4>
      <Collapse
        accordion
        activeKey={activeAccordion}
        onChange={(key) => handleAccordionToggle(key)}
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? <FaAngleUp /> : <FaAngleDown />
        }
        className="custom-collapse"
      >
        {itinerary &&
          itinerary.map((item, index) => (
            <Panel
              header={`Day ${index + 1}`}
              key={index}
              className="custom-panel"
            >
              <div className="ml-2">
                <p className="text-lg font-bold mb-2">
                  Day - {index + 1}
                </p>
                <p className="text-lg font-bold font-serif">
                  {item.description}
                </p>
              </div>
            </Panel>
          ))}
      </Collapse>
    </Col>
  </Row>
</Container>

                {/* {Include and exclude started} */}

                <Container className="mt-5">
                  <Row className="tour__info">
                    <Col>
                      <h4 className="text-bold mb-4">
                        Tour Inclusions and Exclusions
                      </h4>
                      <div className="w-full lg:w-3/4 px-2 lg:px-0 mb-4">
                        <div className="border rounded-lg p-4 border-orange">
                          {included && included.length > 0 && (
                            <div className="mb-4">
                              <h5 className="text-lg font-bold mb-3">
                                Inclusions
                              </h5>
                              <ListGroup>
                                {included.map(
                                  (item, index) =>
                                    item.trim() !== "" && ( // Check if item is not an empty string
                                      <ListGroupItem
                                        key={index}
                                        className="flex items-center"
                                      >
                                        <FaCheckCircle className="text-green-500 mr-2 text-xl" />
                                        <span className="text-lg ml-2">
                                          {item}
                                        </span>
                                      </ListGroupItem>
                                    )
                                )}
                              </ListGroup>
                            </div>
                          )}
                          {excluded && excluded.length > 0 && (
                            <div>
                              <h5 className="text-lg font-bold mb-3">
                                Exclusions
                              </h5>
                              <ListGroup>
                                {excluded.map(
                                  (item, index) =>
                                    item.trim() !== "" && ( // Check if item is not an empty string
                                      <ListGroupItem
                                        key={index}
                                        className="flex items-center"
                                      >
                                        <FaTimesCircle className="text-red-500 mr-2 text-xl" />
                                        <span className="text-lg ml-2">
                                          {item}
                                        </span>
                                      </ListGroupItem>
                                    )
                                )}
                              </ListGroup>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>

                <Container className="mt-5 bg-blue-100">
                  <Row className="tour__info">
                    <Row>
                      <Col>
                        <h4 className="mb-4">Photo Gallery</h4>
                        <div className="d-flex align-items-center mb-4"></div>
                      </Col>
                    </Row>
                    <Col md="3" className="overflow-y-auto max-h-96  ">
                      {visiblePhotos.map((photo, index) => (
                        <img
                          key={index}
                          src={photo}
                          alt={`Photo ${index + 1}`}
                          className="block w-full mb-2 cursor-pointer small-image"
                          onClick={() => handlePhotoClick(photo)}
                        />
                      ))}
                      {photos && photos.length > 6 && !showMore && (
                        <Button
                          color="link"
                          className="text-primary"
                          onClick={() => setShowMore(true)}
                        >
                          See More
                        </Button>
                      )}
                    </Col>
                    <Col md="9">
                      <div className="selected-photo-container ">
                        {selectedPhoto && (
                          <img
                            src={selectedPhoto}
                            alt="Selected Photo"
                            className="selected-photo"
                            onClick={() => setSelectedPhoto(null)}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Container>

                {/* ============ TOUR REVIEWS SECTION START ============ */}
                <div className="tour__reviews mt-4">
                  <h4>Reviews ({reviews?.length} reviews)</h4>

                  <Form onSubmit={submitHandler}>
                    <div className="d-flex align-items-center gap-3 mb-4 rating__group">
                      <span onClick={() => setTourRating(1)}>
                        1 <i class="ri-star-s-fill"></i>
                      </span>
                      <span onClick={() => setTourRating(2)}>
                        2 <i class="ri-star-s-fill"></i>
                      </span>
                      <span onClick={() => setTourRating(3)}>
                        3 <i class="ri-star-s-fill"></i>
                      </span>
                      <span onClick={() => setTourRating(4)}>
                        4 <i class="ri-star-s-fill"></i>
                      </span>
                      <span onClick={() => setTourRating(5)}>
                        5 <i class="ri-star-s-fill"></i>
                      </span>
                    </div>

                    <div className="review__input">
                      <input
                        type="text"
                        ref={reviewMsgRef}
                        placeholder="share your thoughts"
                        required
                      />
                      <button
                        className="btn primary__btn text-white"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </Form>

                  <ListGroup className="user__reviews">
                    {reviews?.map((review) => (
                      <div className="review__item">
                        <img src={avatar} alt="" />

                        <div className="w-100">
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <h5>{review.username}</h5>
                              <p>
                                {new Date(review.createdAt).toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </p>
                            </div>

                            <span className="d-flex align-items-center">
                              {review.rating}
                              <i class="ri-star-s-fill"></i>
                            </span>
                          </div>

                          <h6>{review.reviewText}</h6>
                        </div>
                      </div>
                    ))}
                  </ListGroup>
                </div>
                {/* ============ TOUR REVIEWS SECTION END ============== */}
              </div>
            </Col>

            <Col lg="4">
              <Booking tour={tour} avgRating={avgRating} />
            </Col>
          </Row>
        )}
      </Container>
      {/* Share section */}
      <div className="share-section mt-4 flex flex-col sm:flex-row sm:items-center sm:justify-end mr-48">
        <h4 className="text-lg font-bold mr-8 mt-2">Share Tour:</h4>
        <div className="flex flex-col sm:flex-row sm:space-x-4">
          {/* Facebook share button */}
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-3 sm:py-2 sm:px-4 rounded-lg flex items-center justify-center transition duration-300 mb-2 sm:mb-0 text-sm sm:text-base"
            onClick={() => shareCurrentRoute("facebook")}
          >
            <FaFacebook className="text-xl sm:text-2xl mr-2" />
            Facebook
          </button>
          {/* Twitter share button */}
          <button
            className="bg-blue-400 hover:bg-blue-500 text-white font-bold py-2 px-3 sm:py-2 sm:px-4 rounded-lg flex items-center justify-center transition duration-300 mb-2 sm:mb-0 text-sm sm:text-base"
            onClick={() => shareCurrentRoute("twitter")}
          >
            <FaTwitter className="text-xl sm:text-2xl mr-2" />
            Twitter
          </button>
          {/* WhatsApp share button */}
          <button
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-3 sm:py-2 sm:px-4 rounded-lg flex items-center justify-center transition duration-300 mb-2 sm:mb-0 text-sm sm:text-base"
            onClick={() => shareCurrentRoute("whatsapp")}
          >
            <FaWhatsapp className="text-xl sm:text-2xl mr-2" />
            WhatsApp
          </button>
          {/* You can add more social media buttons/icons here */}
        </div>
      </div>

      {/* 
<TourCard/> */}

      <Newsletter />
    </section>
  );
};

export default TourDetails;
