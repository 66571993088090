import React from 'react';
import './TestimonialPage.css'

const FreeCancellation = () => {
  return (
    <div className="flex justify-center items-center h-60 bg-green-100 custom-light-green">
      <div className="w-80">
        
      <p className="text-center text-lg font-bold text-green-800 font">
    Free Cancellation
</p>

<br />

<p className="text-center text-gray-700">
    You'll receive a full refund if you cancel <br />   at least  24 hours in advance of most experiences.
</p>

      </div>
    </div>
  );
}

export default FreeCancellation;
