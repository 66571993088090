import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import ThankYou from '../pages/ThankYou'
import Home from './../pages/Home'
import Login from './../pages/Login'
import Register from './../pages/Register'
import SearchResultList from './../pages/SearchResultList'
import TourDetails from '../pages/TourDetails'
import Tours from './../pages/Tours'
import TourForm from '../components/admin/CreateTourForm'
import TourList from '../components/Tours/TourList'
import Destinations from '../pages/Destinations'
import DestinationDetails from '../pages/DestinationDetail'
import BlogList from '../pages/Blogs/BlogList'
import BlogPostDetails from '../pages/Blogs/BlogDetails'
import BlogForm from '../components/admin/CreateBlog'
import DestinationForm from '../components/admin/CreateDestinations'
import { IoArrowBackOutline } from 'react-icons/io5'
import AboutUsPage from '../components/About/About'
import ContactUsPage from '../components/Contact/Contact'
import FAQPage from '../components/About/FAQPage'
import DestinationList from '../pages/Destinations/DestinationList'
import CreateBook from '../components/admin/CreateBooking'
import AdminDestinations from '../components/admin/Controller/Destinations'
import AdminTour from '../components/admin/Controller/Tour'
import AdminBlog from '../components/admin/Controller/Blog'
import AdminBooking from '../components/admin/Controller/Boking'
import BahirDar from '../components/HardCode/BahirDar'

// Catagories of tours
import AddisAbabaTours from '../pages/Category/AddisAbabaTours'
import  DayTripsAndExcurions from '../pages/Category/DayTripsAndExcursions'
import Northern from '../pages/Category/NorthernEthiopiaTour'
import Sothern from '../pages/Category/SouthernEthiopiaTour'
import Eastern from '../pages/Category/EasternEthiopiaTour'
import Group from '../pages/Category/GroupTour'
import  FixedDate from '../pages/Category/Fixed-Date Departure Tours'
import MultiDay from '../pages/Category/MultiDayTours'
import EasternEthiopiaTour from '../pages/Category/EasternEthiopiaTour'
import GroupTour from '../pages/Category/GroupTour'
import SouthernEthiopiaTour from '../pages/Category/SouthernEthiopiaTour'
import NewOfferings from '../pages/Category/NewOfferings/NewOffering'
import AdminDashboard from '../components/admin/Dashboard/AdminDashboard'
import CreateTourDest from '../components/admin/CreateDestinations'
import BlogForms from '../components/admin/CreateBlog'
import CoffeeTours from '../pages/Category/Special/CoffeeTours'
import HolidayCard from '../components/Holidays/Holiday'
import AboutUs from '../pages/About'
import TravelTips from '../pages/TravelTips'

const Routers = () => {
   return (
      <Routes>
         {/* <Route path='/' element={<Navigate to='/home'/>} /> */}
         <Route path='/' element={<Home/>} />
         <Route path='/tours' element={<Tours/>} />
         {/* <Route path='/tours/:id' element={<TourDetails/>} /> */}
         <Route path='/login' element={<Login/>} />
         <Route path='/register' element={<Register/>} />
         <Route path='/thank-you' element={<ThankYou/>} />
         <Route path='/tours/search' element={<SearchResultList/>} />
         <Route path='/tour/add' element={< TourForm/>} />
         <Route path='/blogs/create' element={< BlogForm/>} />
         <Route path='/destinations/create' element={< DestinationForm/>} />
         <Route path="/tours/:id" element={<TourDetails/>} />
         <Route path='/tours/list' element={< TourList/>} />
         <Route path='/destinations/list' element={< Destinations/>} />
         <Route path='/blog' element={< BlogList/>} />
         <Route path='/blog/:id' element={< BlogPostDetails/>} />
         <Route path='/about' element={<AboutUs/>} />
         <Route path='/faq' element={<FAQPage/>} />
         <Route path='/terms' element={<AboutUsPage/>} />
         <Route path='/traveltips' element={<TravelTips/>} />
         <Route path='/contact-us' element={<ContactUsPage/>} />
         <Route path='/destinations' element={<DestinationList/>} />
         <Route path='/book-now' element={<CreateBook/>} />
         <Route path='/destination/add' element={<CreateTourDest/>} />
         <Route path='/blog/add' element={<BlogForms/>} />
         {/* <Route path="/tours" element={<TourList/>} /> */}
       
        <Route path='/destinations/list/:id' element={< DestinationDetails/>} />
       
        
        {/* Admin routes */}
        <Route path='/destinations/admin' element={<AdminDestinations/>} />
        <Route path='/tours/admin' element={<AdminTour/>} />
        <Route path='/blogs/admin' element={<AdminBlog/>} />
        <Route path='/books/admin' element={<AdminBooking/>} />
        <Route path='/admin' element={<AdminDashboard/>} />
        {/* Hardcode Destiantions */}
      
        <Route path='/destinations/bahirdar-ethiopia' element={<BahirDar/>} />
        
      

      {/* AddisAbabaTours */}
      <Route path='/addis-ababa-city-tours' element={<AddisAbabaTours/>} />
      <Route path='/day-trips-&-excursions' element={<DayTripsAndExcurions/>} />
      <Route path='/eastern-ethiopia' element={<EasternEthiopiaTour/>} />
      <Route path='/fixed-date-departure-tours' element={<FixedDate/>} />
      <Route path='/group-tours' element={<GroupTour/>} />
      <Route path='/multi-day-tours' element={<MultiDay/>} />
      <Route path='/northern-ethiopia' element={<Northern/>} />
      <Route path='/southern-ethiopia' element={<SouthernEthiopiaTour/>} />
      <Route path='/coffee-tours' element={<CoffeeTours/>} />

      {/* NEWoFFERING */}
      <Route path='/holidays' element={<HolidayCard/>} />

      </Routes>
   )
}

export default Routers