// Holidays.jsx
import React, { useState } from 'react';
import { Row, Col, Card, Button, Modal, Carousel } from 'antd'; // Import Carousel for image slider
import { PlusOutlined } from '@ant-design/icons';
import holidaysData from './holidayData';

const Holidays = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);

  const openModal = (holiday) => {
    setSelectedHoliday(holiday);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="container mx-auto">
      <div className="hero-section text-center py-12 200">
        <h1 className="text-4xl font-bold text-gray-800">Discover Holidays in Ethiopia</h1>
        <p className="text-lg text-gray-600 mt-4">Explore the rich cultural heritage and natural beauty of Ethiopia through its holidays.</p>
      </div>
      <Row gutter={[16, 16]}>
        {holidaysData.map((holiday, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              className="holiday-card"
              hoverable
              cover={<img className="card-image" alt={holiday.name} src={holiday.coverImage} />}
              actions={[
                <Button onClick={() => openModal(holiday)} icon={<PlusOutlined />} key="read-more">Read More</Button>
              ]}
            >
              <Card.Meta title={holiday.name} description={holiday.description} />
            </Card>
          </Col>
        ))}
      </Row>
      <Modal
        className="wide-modal"
        title={selectedHoliday ? selectedHoliday.name : ''}
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
        width={800}
      >
        {selectedHoliday && (
          <>
            <Carousel autoplay autoplaySpeed={1000}>
              <div>
                <img src={selectedHoliday.coverImage} alt={selectedHoliday.name} />
              </div>
              {selectedHoliday.additionalImages.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={selectedHoliday.name} />
                </div>
              ))}
            </Carousel>
            <p>{selectedHoliday.details}</p>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Holidays;
